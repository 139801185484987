import {
  EQuestionItemType,
  EQuestionItemSubType,
  IQuestionResItem,
  IQuestionSurveyModel,
  IQuestionResStar,
  IQuestionResOption,
  IQuestionResMatrix,
  IQuestionResText,
  IQuestionResSensitiveInput,
  IQuestionChangeParams,
  IQuestionResTime,
} from '@tux-meter-ui/interface/survey';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { CustomValidateObj } from 'tdesign-react';
import { IQuestionItemRef, IQuestionItemValidateResult } from './interface';
import { QuestionMatrix } from './QuestionMatrix';
import { QuestionOption } from './QuestionOption';
import { QuestionOptionImage } from './QuestionOptionImage';
import { QuestionSensitive } from './QuestionSensitive';
import { QuestionStar } from './QuestionStar';
import { QuestionTextArea } from './QuestionTextArea';
import { QuestionTime } from './QuestionTime';
import './index.less';

/**
 * 问卷问题实例，用于校验该题目是否通过校验
 */
export abstract class QuestionItemIns {
  public abstract validate?: () => Promise<CustomValidateObj>;
}

interface IQuestionItemProps {
  /**
   * 问题change事件
   * @param value
   * @param oldValue
   * @param {IQuestionChangeParams} params
   * @param {boolean} params.isDisplayChange 是否需要变更展示，当选择题类型被选中时，需要展示关联的下一道题
   * @returns
   */
  onChange: (value: IQuestionResItem, oldValue: IQuestionResItem, params: IQuestionChangeParams) => void;
  /**
   * 触发问题焦点
   * @returns
   */
  onFocus?: () => void;
  /**
   * 问题离开焦点
   * @returns
   */
  onBlur?: () => void;
  /**
   * 问题序号
   */
  index?: number;
  value: IQuestionResItem;
  /**
   * 展示出来的题型并已填答结果（用于题型上下文关联校验等操作）
   */
  displayOfQuestionListResults: IQuestionResItem[];
  /**
   * 问卷原始数据对象
   */
  surveyModel: IQuestionSurveyModel;
  /**
   * 已填答的数据字典
   */
  answerMaps: Record<string, IQuestionResItem>;
}

/**
 * 问卷问题Base组件（根据问题的type和sub_type分别渲染不同的问题组件）
 * @param {IQuestionItemProps} props
 * @returns
 */
export const QuestionItem = forwardRef<IQuestionItemRef, IQuestionItemProps>(
  ({ onChange, onFocus, onBlur, index, value, displayOfQuestionListResults, surveyModel, answerMaps }, ref) => {
    const questionItemRef = useRef<IQuestionItemRef>(null);
    const ele = useMemo(() => {
      if (!value) {
        return null;
      }
      const { model } = value;
      const { sub_type } = model;
      switch (value.type) {
        case EQuestionItemType.Star:
          return (
            <QuestionStar
              ref={questionItemRef}
              index={index}
              value={value as IQuestionResStar}
              onChange={(newValue, oldValue, params) => onChange(newValue, oldValue, params)}
            />
          );
        case EQuestionItemType.Option:
          if (sub_type === EQuestionItemSubType.IMAGE_RADIO || sub_type === EQuestionItemSubType.IMAGE_CHECKBOX) {
            return (
              <QuestionOptionImage
                ref={questionItemRef}
                index={index}
                value={value as IQuestionResOption}
                onChange={(newValue, oldValue, params) => onChange(newValue, oldValue, params)}
              />
            );
          }
          return (
            <QuestionOption
              ref={questionItemRef}
              index={index}
              value={value as IQuestionResOption}
              onChange={(newValue, oldValue, params) => onChange(newValue, oldValue, params)}
            />
          );
        case EQuestionItemType.Time:
          return (
            <QuestionTime
              ref={questionItemRef}
              index={index}
              value={value as IQuestionResTime}
              onChange={(newValue, oldValue, params) => onChange(newValue, oldValue, params)}
            />
          );
        case EQuestionItemType.Matrix:
          return (
            <QuestionMatrix
              ref={questionItemRef}
              index={index}
              displayOfQuestionListResults={displayOfQuestionListResults}
              surveyModel={surveyModel}
              answerMaps={answerMaps}
              value={value as IQuestionResMatrix}
              onChange={(newValue, oldValue, params) => onChange(newValue, oldValue, params)}
            />
          );
        case EQuestionItemType.Text:
          return (
            <QuestionTextArea
              ref={questionItemRef}
              index={index}
              value={value as IQuestionResText}
              onChange={(newValue, oldValue, params) => onChange(newValue, oldValue, params)}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          );
        case EQuestionItemType.SensitiveInput:
          return (
            <QuestionSensitive
              ref={questionItemRef}
              index={index}
              value={value as IQuestionResSensitiveInput}
              onChange={(newValue, oldValue, params) => onChange(newValue, oldValue, params)}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          );
        default:
          return null;
      }
    }, [value, index, onChange, onFocus, onBlur, displayOfQuestionListResults, surveyModel, answerMaps]);

    function validate(): IQuestionItemValidateResult {
      // 非必填的情况下，直接通过
      if (!value.model.required) {
        return {
          result: true,
          errors: [],
        };
      }
      const res = questionItemRef.current?.validate() ?? {
        result: false,
        errors: [],
      };
      return res;
    }

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return <div>{ele}</div>;
  },
);
