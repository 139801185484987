import _ from 'lodash';

export const HtmlHelper = {
  /**
   * 设置文本为当前html标题
   * @param title
   */
  setTitle: (title?: string) => {
    if (title !== undefined && _.isString(title)) {
      document.title = title;
    }
  },
};
