import React, { Suspense } from 'react';
import { HashRouter as Router, Routes } from 'react-router-dom';
import { getRouterComponents, routes } from './router';
import './App.less';

const App: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>{getRouterComponents(routes)}</Routes>
      </Suspense>
    </Router>
  );
};

export default App;
