import React, { Component } from 'react';

interface IErrorBoundaryProps {
  /**
   * 渲染自定义错误ui
   * @param error
   * @returns
   */
  renderErrorContent?: (error: Error, children: JSX.Element) => null | JSX.Element | JSX.Element[];
  children: JSX.Element;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

/**
 * 捕获组件树中的错误并显示回退 UI
 */
export class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    console.log('ErrorBoundary error:', error);
    // 更新 state 以触发下一次渲染时显示回退 UI
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // 你也可以将错误日志上报给服务器
    console.error('Error caught by ErrorBoundary: ', error, errorInfo);
  }

  render() {
    if (this.props.renderErrorContent && this.state.error) {
      return this.props.renderErrorContent(this.state.error, this.props.children);
    }
    return this.props.children;
  }
}
