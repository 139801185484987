import _ from 'lodash';

/**
 * 判断是否数字
 * @param value
 */
export function isNumber(value?: string | number | null | boolean) {
  if (value === '' || value === null) {
    return false;
  }
  if (value === true || value === false) {
    return false;
  }
  const callType = Object.prototype.toString.call(value);
  if (callType.indexOf('Array') > -1) {
    return false;
  }
  const num = Number(value);
  if (num.toString() === 'NaN') {
    return false;
  }
  return _.isNumber(num);
}
