import { IRouterComponent } from '@tux-meter-ui/h5/utils/router-helper';
import Csat from '@tux-meter-ui/h5/view/Csat';
import CsatOverflow from '@tux-meter-ui/h5/view/CsatOverflow';
import SurveyPreview from '@tux-meter-ui/h5/view/SurveyPreview';
import SurveySuccessView from '@tux-meter-ui/h5/view/SurveySuccessView';
import { Route } from 'react-router-dom';

// 懒加载 SurveySuccessView 组件
export const routes: IRouterComponent[] = [
  {
    name: 'Home',
    path: '/',
    component: <Csat />,
  },
  {
    name: 'Csat',
    path: '/tux/:survey_type',
    component: <Csat />,
  },
  {
    name: 'Csat',
    path: '/tux/overflow/:survey_type',
    component: <CsatOverflow />,
  },
  {
    name: 'SurveyPreview',
    path: '/survey-preview',
    component: <SurveyPreview />,
  },
  {
    name: 'SurveySuccessView',
    path: '/survey-success-view',
    component: <SurveySuccessView />,
  },
  {
    name: 'TestParent',
    path: '/test-parent',
    component: <SurveySuccessView />,
    children: [
      {
        name: 'TestChild1',
        path: 'test-child-1',
        component: <SurveySuccessView />,
      },
      {
        name: 'TestChild2',
        path: 'test-child-2',
        component: <SurveySuccessView />,
      },
    ],
  },
];

/**
 * 根据 routes 获取所有路由组件
 * @param routes
 * @returns
 */
export function getRouterComponents(routes: IRouterComponent[]) {
  return routes.map((route) => {
    const { path, component, children } = route;
    if (children && children.length > 0) {
      return (
        <Route key={path} path={path} element={component}>
          {getRouterComponents(children)}
        </Route>
      );
    }
    return <Route key={path} path={path} element={component} />;
  });
}
