// simpleDOMPurify.ts

/**
 * xss攻击
 */
export class SimpleDOMPurify {
  private allowedTags: Set<string>;
  private allowedAttributes: Set<string>;
  private disallowedContentPatterns: RegExp[];

  constructor() {
    // 定义允许的标签和属性
    this.allowedTags = new Set(['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'ul', 'ol', 'li', 'span', 'div']);
    this.allowedAttributes = new Set(['href', 'title', 'target']);
    // 定义不允许的内容模式
    this.disallowedContentPatterns = [
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, // 移除 <script> 标签及其内容
      /alert\s*\([^)]*\)\s*;?/gi, // 移除 alert 函数调用
      /javascript\s*:/gi, // 移除 javascript: 协议
      /on\w+\s*=\s*(['"]).*?\1/gi, // 移除事件处理程序属性
    ];
  }

  sanitize(dirty: string): string {
    // 移除不允许的标签
    dirty = dirty.replace(/<[^>]+>/g, (match) => {
      const tagMatch = match.match(/^<\/?([a-z][a-z0-9]*)\b/i);
      if (tagMatch) {
        const tagName = tagMatch[1].toLowerCase();
        if (this.allowedTags.has(tagName)) {
          // 处理允许的标签，移除不允许的属性
          return match.replace(/(\s+[a-z-]+)(?:\s*=\s*(?:"[^"]*"|'[^']*'|[^\s>]+))?/gi, (attrMatch, attrName) => {
            return this.allowedAttributes.has(attrName.trim().toLowerCase()) ? attrMatch : '';
          });
        }
      }
      return ''; // 移除不允许的标签
    });

    // 移除不允许的内容
    this.disallowedContentPatterns.forEach((pattern) => {
      dirty = dirty.replace(pattern, '');
    });

    return dirty;
  }
}
