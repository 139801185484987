import React, { useEffect, useState } from 'react';
import { TooltipProps } from 'tdesign-react';
import './index.less';

interface ITitleTagProps extends TooltipProps {
  onClose?: () => void;
}

/**
 * 标题标签组件
 * @param {ITitleTagProps} props
 * @returns
 */
export const TitleTag: React.FC<ITitleTagProps> = ({ children, duration, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (duration !== undefined && duration > 0) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose?.();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [children, onClose, duration]);

  if (children === undefined || !isVisible) {
    return null;
  }

  return <span className="title-tag-error cus-shake fs-14px">{children}</span>;
};
