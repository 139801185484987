import {
  TuxSurveyConfig,
  TuxMeterSDK,
  DisappearReason,
  TriggerErrorCode,
  ISubmitSurveyPayload,
} from '@tencent/tux-meter-js-sdk-core';
import { ErrorBoundary } from '@tux-meter-ui/components/ErrorBoundary';
import { EScreenModalType, ScreenModal } from '@tux-meter-ui/components/ScreenModal';
import { ITuxNativeBaseSurveyViewRef, TuxNativeBaseSurveyView } from '@tux-meter-ui/components/TuxNativeBaseSurveyView';
import { TuxNativeEmbeddedView } from '@tux-meter-ui/components/TuxNativeEmbeddedView';
import { TuxNativeFullView } from '@tux-meter-ui/components/TuxNativeFullView';
import { TuxNativeStarView } from '@tux-meter-ui/components/TuxNativeStarView';
import { TuxPopupSurveyView } from '@tux-meter-ui/components/TuxPopupSurveyView';
import { addQueryArgs } from '@tux-meter-ui/utils/url';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { TuxUIConstants } from '../constant';
import {
  ESurveyCloseViewType,
  IGetH5SurveyElementParams,
  IShowTuxCommonSurveyDialogParams,
  IShowTuxWebViewSurveyDialogParams,
} from './inteface';
import 'tdesign-react/es/style/index.css';
import '@tux-meter-ui/assets/css/base.less';
import '@tux-meter-ui/assets/css/td-ext.less';

/**
 * Tux问卷UI
 */
export class TuxMeterUI {
  TAG = 'TuxMeterUI';
  private static instance: TuxMeterUI;
  private tuxMeterSDKInstance: TuxMeterSDK;

  constructor(tuxMeterSDKInstance: TuxMeterSDK) {
    this.tuxMeterSDKInstance = tuxMeterSDKInstance;
  }

  public static getInstance(tuxMeterSDKInstance: TuxMeterSDK): TuxMeterUI {
    if (!TuxMeterUI.instance) {
      TuxMeterUI.instance = new TuxMeterUI(tuxMeterSDKInstance);
    }
    return TuxMeterUI.instance;
  }

  /**
   * 获取单例
   * @returns
   */
  public static getSingleInstance(): TuxMeterUI {
    return TuxMeterUI.instance;
  }

  /**
   * 获取sdk配置
   * @returns
   */
  public getSDKSetting() {
    return this.tuxMeterSDKInstance?.getSDKSetting();
  }

  /**
   * 弹窗方式
   */
  public showTuxCommonSurveyDialog(params: IShowTuxCommonSurveyDialogParams): void {
    const { surveyConfig } = params;
    if (!this.isValidNativeSurveyConfig(surveyConfig)) {
      this.reportSurveyFailedEvent(surveyConfig, TriggerErrorCode.SURVEY_INVALID);
      return;
    }

    if (!this.isTuxUIDialog(surveyConfig)) {
      this.reportSurveyFailedEvent(surveyConfig, TriggerErrorCode.SCENE_ID_NOT_MATCH);
      return;
    }

    const resource = surveyConfig.getResource();
    if (resource == null) {
      this.reportSurveyFailedEvent(surveyConfig, TriggerErrorCode.SURVEY_INVALID);
      return;
    }
    const componentType = resource.component_type;
    switch (componentType) {
      case TuxUIConstants.TRIGGER_STYLE_DIALOG_FULLSCREEN:
        this.showTuxNativeWithH5SurveyDialog(params);
        break;
      case TuxUIConstants.TRIGGER_STYLE_TIPS:
      case TuxUIConstants.TRIGGER_STYLE_DIALOG_FLOAT:
        this.showTuxNativeSurveyDialog(params);
        break;
      default:
        this.reportSurveyFailedEvent(surveyConfig, TriggerErrorCode.SCENE_ID_NOT_MATCH);
        break;
    }
  }

  /**
   * 获取h5问卷iframe元素(实则在前端是iframe元素)
   * @param params
   * @returns
   */
  private getH5SurveyElement(params: IGetH5SurveyElementParams) {
    const { surveyConfig, tuxEventListener, starOptionId } = params;
    if (!this.isValidWebViewSurveyConfig(surveyConfig)) {
      return;
    }
    // 获取带有ext_info的h5 url
    const curr_url = surveyConfig.getUrlOfParamWithExtInfo();
    const url_with_ext_info = curr_url ? curr_url : '';
    const queryArgs = [{ key: 'cb_fns', value: 'success,cancel' }];
    if (starOptionId) {
      queryArgs.push({ key: 'option_id', value: starOptionId });
    }
    const url = addQueryArgs(url_with_ext_info, queryArgs);
    // 创建一个 iframe 元素
    const iframe = document.createElement('iframe');
    // 设置 iframe 的属性
    iframe.src = url;
    iframe.style.position = 'fixed';
    iframe.style.top = '0';
    iframe.style.left = '0';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    iframe.style.zIndex = '9999';
    iframe.style.backgroundColor = '#fff';

    const handleMessage = (event: { origin: string; data: string }) => {
      // 确保消息来源是可信的
      if (event.origin.indexOf('tux') === -1) {
        return;
      }
      if (event.data === 'success') {
        this.tuxMeterSDKInstance.getSurveyEventCallback()?.onSubmit(surveyConfig);
        tuxEventListener?.onSurveySubmit?.({ surveyConfig });
        iframe.remove();
        // 卸载监听
        window.removeEventListener('message', handleMessage, false);
      } else if (event.data === 'cancel') {
        this.tuxMeterSDKInstance.getSurveyEventCallback()?.onDisappear(surveyConfig, DisappearReason.CLICK_X);
        tuxEventListener?.onSurveyClose?.(
          {
            surveyConfig,
            reason: DisappearReason.CLICK_X,
          },
          ESurveyCloseViewType.SURVEY,
        );
        iframe.remove();
        // 卸载监听
        window.removeEventListener('message', handleMessage, false);
      }
    };
    window.addEventListener('message', handleMessage, false);
    return iframe;
  }

  /**
   * 打开对应问卷h5的webview（实际上是一个全屏的iframe，监听了success和cancel事件）
   */
  public showTuxWebViewSurveyDialog(params: IShowTuxWebViewSurveyDialogParams): void {
    const { surveyConfig, tuxEventListener } = params;
    const iframe = this.getH5SurveyElement(params);
    if (iframe) {
      // 将 iframe 插入到 body 中
      document.body.appendChild(iframe);
      tuxEventListener?.onSurveyDisplayed?.({ surveyConfig });
    }
  }

  /**
   * 问卷View方式
   */
  public getTuxNativeSurveyView(
    params: Omit<IShowTuxCommonSurveyDialogParams, 'starElement'>,
  ): HTMLElement | undefined {
    const { surveyConfig, tuxEventListener } = params;
    try {
      if (this.isTuxSurveyTipsStyle(surveyConfig)) {
        const iframe = this.getH5SurveyElement(params);
        return iframe;
      } else if (this.isTuxUIEmbeddedFloat(surveyConfig)) {
        // 展示量表题
        const starEle = document.createElement('div');
        const starReactRoot = ReactDOM.createRoot(starEle);
        starReactRoot.render(
          <TuxNativeStarView
            surveyConfig={surveyConfig}
            tuxEventListener={{
              onStarQuestionClick: (startQuestionClickParams) => {
                tuxEventListener?.onStarQuestionClick?.(startQuestionClickParams);
                // 构建问卷视图
                const surveyEle = document.createElement('div');
                surveyEle.className = 'tux-native-view-container-type-1';
                surveyEle.style.height = '100%';
                document.body.appendChild(surveyEle);
                const surveyReactRoot = ReactDOM.createRoot(surveyEle);
                const componentType = surveyConfig.getResource().component_type;
                const screenModalType =
                  componentType === TuxUIConstants.TRIGGER_STYLE_EMBEDDED_FLOAT
                    ? EScreenModalType.FULL
                    : EScreenModalType.HALF;
                surveyReactRoot.render(
                  <ScreenModal
                    type={screenModalType}
                    visible={true}
                    onClose={() => {
                      tuxEventListener?.onSurveyClose?.(
                        {
                          surveyConfig,
                          reason: DisappearReason.CLICK_MASK,
                        },
                        ESurveyCloseViewType.SURVEY,
                      );
                    }}
                  >
                    <TuxNativeFullView
                      {...params}
                      tuxEventListener={{
                        ...params.tuxEventListener,
                        onSurveySubmit: (p) => {
                          const { surveyConfig, submitAnswerParams } = p;
                          if (submitAnswerParams) {
                            const { payload, tuxSurveyType } = submitAnswerParams;
                            const newPayload: ISubmitSurveyPayload = {
                              zone_id: this.tuxMeterSDKInstance.getSDKSetting()?.getZoneId?.() ?? '',
                              openid: surveyConfig.getOpenId(),
                              ...payload,
                            };
                            this.tuxMeterSDKInstance.submitAnswer?.(surveyConfig, newPayload, tuxSurveyType);
                          }
                          params?.tuxEventListener?.onSurveySubmit?.(p);
                          // 移除问卷
                          surveyEle?.remove?.();
                        },
                        onSurveyClose: (p) => {
                          const { surveyConfig, reason } = p;
                          this.tuxMeterSDKInstance.getSurveyEventCallback?.()?.onDisappear?.(surveyConfig, reason);
                          params?.tuxEventListener?.onSurveyClose?.(p, ESurveyCloseViewType.SURVEY);
                          // 移除问卷
                          surveyEle?.remove?.();
                        },
                      }}
                      starOptionId={startQuestionClickParams.option.id}
                    />
                  </ScreenModal>,
                );
              },
            }}
          />,
        );
        return starEle;
      } else {
        // 直接展示原生问卷
        const surveyEle = document.createElement('div');
        surveyEle.className = 'tux-native-view-container-type-2';
        surveyEle.style.height = '100%';
        const surveyReactRoot = ReactDOM.createRoot(surveyEle);
        surveyReactRoot.render(
          <ErrorBoundary>
            <TuxNativeEmbeddedView
              {...params}
              tuxEventListener={{
                ...params.tuxEventListener,
                onQuestionClick: (p) => {
                  this.tuxMeterSDKInstance.getSurveyEventCallback()?.onClick(p.surveyConfig);
                },
                onSurveySubmit: (p) => {
                  const { surveyConfig, submitAnswerParams } = p;
                  if (submitAnswerParams) {
                    const { payload, tuxSurveyType } = submitAnswerParams;
                    const newPayload: ISubmitSurveyPayload = {
                      zone_id: this.tuxMeterSDKInstance.getSDKSetting()?.getZoneId?.() ?? '',
                      openid: surveyConfig.getOpenId(),
                      ...payload,
                    };
                    this.tuxMeterSDKInstance.submitAnswer?.(surveyConfig, newPayload, tuxSurveyType);
                  }
                  params?.tuxEventListener?.onSurveySubmit?.(p);
                  // 移除问卷
                  // surveyEle?.remove?.();
                },
                onSurveyClose: (p) => {
                  this.tuxMeterSDKInstance.getSurveyEventCallback()?.onDisappear(surveyConfig, DisappearReason.CLICK_X);
                  params?.tuxEventListener?.onSurveyClose?.(p, ESurveyCloseViewType.SURVEY);
                  // 移除问卷
                  // surveyEle?.remove?.();
                },
              }}
            />
          </ErrorBoundary>,
        );
        return surveyEle;
      }
    } catch (err) {
      // 检查错误对象是否是 Error 类型
      if (err instanceof Error) {
        console.error('获取原生视图 捕获到错误:', err.message);
        console.error('获取原生视图 错误堆栈:', err.stack);
      } else {
        // 如果错误对象不是 Error 类型，直接打印
        console.error('获取原生视图 捕获到未知类型的错误:', err);
      }
    }
    this.reportSurveyFailedEvent(surveyConfig, TriggerErrorCode.SCENE_ID_NOT_MATCH);
    return;
  }

  /**
   * 判断是弹窗类型（component_type: 0|1|5）
   */
  public isTuxUIDialog(surveyConfig: TuxSurveyConfig): boolean {
    if (!this.isValidNativeSurveyConfig(surveyConfig)) {
      return false;
    }

    const resource = surveyConfig.getResource();
    if (!resource) {
      return false;
    }

    const componentType = resource.component_type;
    return TuxUIConstants.isDialogTrigger(componentType);
  }

  /**
   * 判断是嵌入+半浮层（component_type: 3|4）
   */
  public isTuxUIEmbeddedFloat(surveyConfig: TuxSurveyConfig): boolean {
    if (!this.isValidNativeSurveyConfig(surveyConfig)) {
      return false;
    }

    const resource = surveyConfig.getResource();
    if (!resource) {
      return false;
    }

    const componentType = resource.component_type;
    return TuxUIConstants.isEmbeddedTrigger(componentType);
  }

  /**
   * 判断是嵌入+展开（component_type: 6）
   */
  public isTuxUINative(surveyConfig: TuxSurveyConfig): boolean {
    if (!this.isValidNativeSurveyConfig(surveyConfig)) {
      return false;
    }

    const resource = surveyConfig.getResource();
    if (!resource) {
      return false;
    }

    const componentType = resource.component_type;
    return TuxUIConstants.isNativeTrigger(componentType);
  }

  /**
   * 判断是tips组件类型，已H5方式打开（component_type: 1）
   */
  public isTuxSurveyTipsStyle(surveyConfig: TuxSurveyConfig): boolean {
    if (!this.isValidNativeSurveyConfig(surveyConfig)) {
      return false;
    }

    const resource = surveyConfig.getResource();
    if (!resource) {
      return false;
    }

    const componentType = resource.component_type;
    return TuxUIConstants.isTriggerStyleTips(componentType);
  }

  /**
   * 上报问卷曝光事件
   */
  public reportSurveyExposureEvent(config: TuxSurveyConfig): void {
    const surveyEventCallback = this.tuxMeterSDKInstance.getSurveyEventCallback();
    if (surveyEventCallback) {
      surveyEventCallback.onShow(config);
      surveyEventCallback.onReceiveTriggerErrorCode(config, TriggerErrorCode.SUCCESS);
    }
  }

  /**
   * 上报问卷曝光失败事件
   */
  public reportSurveyFailedEvent(config: TuxSurveyConfig, code: TriggerErrorCode): void {
    const surveyEventCallback = this.tuxMeterSDKInstance.getSurveyEventCallback();
    if (surveyEventCallback) {
      surveyEventCallback.onReceiveTriggerErrorCode(config, code);
    }
  }

  /**
   * 弹出Tux原生问卷Dialog（component_type:5）
   * @param params
   */
  private showTuxNativeSurveyDialog(params: IShowTuxCommonSurveyDialogParams): void {
    const { surveyConfig, tuxEventListener } = params;
    if (this.isTuxSurveyTipsStyle(surveyConfig)) {
      // tips组件直接使用iframe打开h5问卷
      this.showTuxWebViewSurveyDialog(params);
      return;
    } else if (this.isTuxUIEmbeddedFloat(surveyConfig)) {
      // 如果是嵌入式+弹窗
    }
    // 构建问卷视图
    const surveyEle = document.createElement('div');
    surveyEle.className = 'tux-native-survey-dialog';
    surveyEle.style.height = '100%';
    document.body.appendChild(surveyEle);
    const surveyReactRoot = ReactDOM.createRoot(surveyEle);
    const componentType = surveyConfig.getResource().component_type;
    const screenModalType =
      componentType === TuxUIConstants.TRIGGER_STYLE_EMBEDDED_FLOAT ? EScreenModalType.FULL : EScreenModalType.HALF;
    surveyReactRoot.render(
      <ScreenModal
        type={screenModalType}
        visible={true}
        onClose={() => {
          this.tuxMeterSDKInstance.getSurveyEventCallback()?.onDisappear(surveyConfig, DisappearReason.SLIDE_DOWN);
          tuxEventListener?.onSurveyClose?.(
            {
              surveyConfig,
              reason: DisappearReason.SLIDE_DOWN,
            },
            ESurveyCloseViewType.SURVEY,
          );
        }}
      >
        <TuxNativeFullView
          {...params}
          tuxEventListener={{
            ...params.tuxEventListener,
            onSurveySubmit: (p) => {
              const { surveyConfig, submitAnswerParams } = p;
              if (submitAnswerParams) {
                const { payload, tuxSurveyType } = submitAnswerParams;
                const newPayload: ISubmitSurveyPayload = {
                  zone_id: this.tuxMeterSDKInstance.getSDKSetting()?.getZoneId?.() ?? '',
                  openid: surveyConfig.getOpenId(),
                  ...payload,
                };
                this.tuxMeterSDKInstance.submitAnswer?.(surveyConfig, newPayload, tuxSurveyType);
              }
              params?.tuxEventListener?.onSurveySubmit?.(p);
              // 移除问卷
              surveyEle?.remove?.();
            },
            onSurveyClose: (p) => {
              this.tuxMeterSDKInstance.getSurveyEventCallback()?.onDisappear(surveyConfig, DisappearReason.CLICK_X);
              params?.tuxEventListener?.onSurveyClose?.(p, ESurveyCloseViewType.SURVEY);
              // 移除问卷
              surveyEle?.remove?.();
            },
          }}
        />
      </ScreenModal>,
    );
  }

  /**
   * showTuxNativeWithH5SurveyDialog（评分题、表情题勾选后，弹出H5问卷， component_type: 0）
   */
  private showTuxNativeWithH5SurveyDialog(params: IShowTuxCommonSurveyDialogParams): void {
    const { surveyConfig, starElement, tuxEventListener } = params;
    const ele = document.createElement('div');
    ele.className = 'tux-popup-survey-root-container';
    starElement.appendChild(ele);
    const root = ReactDOM.createRoot(ele);
    root.render(
      <TuxPopupSurveyView
        surveyConfig={surveyConfig}
        tuxMeterSDKInstance={this.tuxMeterSDKInstance}
        tuxEventListener={{
          onStarQuestionClick: (startQuestionClickParams) => {
            tuxEventListener?.onStarQuestionClick?.(startQuestionClickParams);
            // 当点击量表题弹窗时，打开全屏iframe h5(并传入已选中的量表题选项)
            this.showTuxWebViewSurveyDialog({ ...params, starOptionId: startQuestionClickParams.option.id });
          },
        }}
        onClose={() => {
          this.tuxMeterSDKInstance.getSurveyEventCallback()?.onDisappear(surveyConfig, DisappearReason.CLICK_X);
          // 量表题关闭
          tuxEventListener?.onSurveyClose?.(
            {
              surveyConfig,
              reason: DisappearReason.CLICK_X,
            },
            ESurveyCloseViewType.STAR,
          );
        }}
      />,
    );
  }

  /**
   * 获取原生问卷完整视图（包含答题进度条、和底部提交按钮）
   * @param params
   * @returns
   */
  static getTuxNativeFullView(params: Omit<IShowTuxWebViewSurveyDialogParams, 'starElement'>): HTMLElement | undefined {
    const { surveyConfig, setting, tuxEventListener, starOptionId } = params;
    const surveyEle = document.createElement('div');
    surveyEle.className = 'tux-native-full-view-container';
    surveyEle.style.minHeight = '100px';
    const surveyReactRoot = ReactDOM.createRoot(surveyEle);
    surveyReactRoot.render(
      <TuxNativeFullView
        surveyConfig={surveyConfig}
        setting={setting}
        tuxEventListener={tuxEventListener}
        starOptionId={starOptionId}
      />,
    );
    return surveyEle;
  }

  /**
   * 获取原生问卷基础视图（仅渲染问题，不包含答题进度条和提交按钮）
   * @param params
   * @returns
   */
  static getTuxNativeBaseSurveyView(params: Omit<IShowTuxWebViewSurveyDialogParams, 'starElement'>) {
    const { surveyConfig, setting, tuxEventListener, starOptionId } = params;
    const tuxNativeBaseSurveyViewRef = React.createRef<ITuxNativeBaseSurveyViewRef>();
    const surveyEle = document.createElement('div');
    surveyEle.className = 'tux-native-base-survey-container';
    surveyEle.style.minHeight = '100px';
    const surveyReactRoot = ReactDOM.createRoot(surveyEle);
    surveyReactRoot.render(
      <ErrorBoundary>
        <TuxNativeBaseSurveyView
          ref={tuxNativeBaseSurveyViewRef}
          surveyConfig={surveyConfig}
          setting={setting}
          tuxEventListener={tuxEventListener}
          starOptionId={starOptionId}
          onDisplayQuestionsChange={(displayQuestions) => {
            tuxEventListener?.onDisplayQuestionsChange?.(displayQuestions);
          }}
        />
      </ErrorBoundary>,
    );
    /**
     * 提交问题（内部校验问题必填项）
     * @returns
     */
    const submit = async () => {
      return await tuxNativeBaseSurveyViewRef.current?.submit();
    };
    return {
      surveyEle,
      ref: tuxNativeBaseSurveyViewRef,
      /**
       * 提交问题（内部校验问题必填项）
       */
      submit,
    };
  }

  /**
   * 是否有效的h5问卷配置
   * @param surveyConfig
   * @returns
   */
  private isValidWebViewSurveyConfig(surveyConfig: TuxSurveyConfig): boolean {
    if (surveyConfig === null || !surveyConfig.getUrlOfParamWithExtInfo()) {
      return false;
    }
    // 获取带有ext_info的h5 url
    const curr_url = surveyConfig.getUrlOfParamWithExtInfo();
    const url_with_ext_info = curr_url ? curr_url : '';
    return url_with_ext_info !== '';
  }

  private isValidNativeSurveyConfig(surveyConfig: TuxSurveyConfig): boolean {
    if (surveyConfig === null || surveyConfig.getSurvey() === null) {
      return false;
    }

    const survey = surveyConfig.getSurvey();
    if (survey === null) {
      return false;
    }

    const pages = survey.pages;
    if (pages === null || pages.length === 0) {
      return false;
    }

    const page = pages[0];
    const questions = page.questions;
    if (questions === null || questions.length === 0) {
      return false;
    }

    return true;
  }
}

export default TuxMeterUI;
