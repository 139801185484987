import { Col, Row } from '@tux-meter-ui/components/Grid';
import { useState } from 'react';
import { Icon } from 'tdesign-icons-react';
import './index.less';

interface IProps {
  onSubmit?: () => Promise<boolean | void>;
}

/**
 * 提交按钮
 * @param {IProps} props
 * @returns
 */
export const SubmitButton = ({ onSubmit }: IProps) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const submit = async () => {
    setSubmitLoading(true);
    await onSubmit?.();
    setTimeout(() => {
      setSubmitLoading(false);
    }, 2000);
  };
  return (
    <div
      className="tux-submit-button"
      onClick={() => {
        if (!submitLoading) {
          submit();
        }
      }}
    >
      <Row align="middle" justify="center" gutter={16} style={{ height: '100%', color: '#fff' }}>
        {submitLoading ? (
          <>
            <Col key="loading-icon">
              <Icon name="loading" />
            </Col>
            <Col key="loading-text">提交中</Col>
          </>
        ) : (
          <Col>提交</Col>
        )}
      </Row>
    </div>
  );
};
