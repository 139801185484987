import classNames from 'classnames';
import { isObject } from 'lodash';
import React, { forwardRef, useContext } from 'react';
import { StyledProps } from '../common';
import { colDefaultProps } from '../defaultProps';
import useDefaultProps from '../hooks/useDefaultProps';
import { RowContext } from '../Row';
import { TdColProps, TdRowProps } from '../type';
import './index.less';

type FlexType = number | 'none' | 'auto' | string;

export interface ColProps extends TdColProps, StyledProps {
  key?: string;
  tag?: keyof JSX.IntrinsicElements; // 确保 tag 是有效的 HTML 标签
  /**
   * 均分个数，比如传入5，表示1/5=20%，Col宽度20%
   */
  avg?: number;
  /**
   * 文本内容
   */
  children?: JSX.IntrinsicElements['div']['children'];
}

const calcColPadding = (gutter: TdRowProps['gutter'], currentSize: string) => {
  const paddingObj = {};
  if (typeof gutter === 'number') {
    Object.assign(paddingObj, {
      paddingLeft: `${gutter / 2}px`,
      paddingRight: `${gutter / 2}px`,
    });
  } else if (Array.isArray(gutter) && gutter.length) {
    if (typeof gutter[0] === 'number') {
      Object.assign(paddingObj, {
        paddingLeft: `${gutter[0] / 2}px`,
        paddingRight: `${gutter[0] / 2}px`,
      });
    }
    // pixui环境中不支持row-gap属性，会导致最后一行多出gutter[1]的高度
    if (typeof gutter[1] === 'number') {
      Object.assign(paddingObj, {
        paddingBottom: `${gutter[1]}px`,
      });
    }
    const gutter0 = gutter[0] as Record<string, any>;
    if (isObject(gutter0) && gutter0[currentSize]) {
      Object.assign(paddingObj, {
        paddingLeft: `${gutter0[currentSize] / 2}px`,
        paddingRight: `${gutter0[currentSize] / 2}px`,
      });
    }
  } else if (isObject(gutter) && (gutter as Record<string, any>)[currentSize]) {
    const gutterObj = gutter as Record<string, any>;
    Object.assign(paddingObj, {
      paddingLeft: `${gutterObj[currentSize] / 2}px`,
      paddingRight: `${gutterObj[currentSize] / 2}px`,
    });
  }
  return paddingObj;
};

const parseFlex = (flex: FlexType) => {
  if (typeof flex === 'number') {
    return { flexGrow: flex, flexShrink: 1, flexBasis: '0%' };
  }

  if (flex === 'none') {
    return { flexGrow: 0, flexShrink: 0, flexBasis: 'auto' };
  }

  if (flex === 'auto') {
    return { flexGrow: 1, flexShrink: 1, flexBasis: 'auto' };
  }

  const flexParts = flex.split(' ');
  const flexGrow = parseFloat(flexParts[0]) || 0;
  const flexShrink = parseFloat(flexParts[1]) || 1;
  const flexBasis = flexParts[2] || '0%';

  return { flexGrow, flexShrink, flexBasis };
};

const Col = forwardRef<HTMLElement, ColProps>((props, ref) => {
  const {
    flex,
    offset,
    order,
    pull,
    push,
    span,
    tag = 'div', // 默认标签为 div
    className,
    avg,
    children,
    style: propStyle,
    ...otherColProps
  } = useDefaultProps<ColProps>(props, colDefaultProps);

  const { gutter: rowGutter, size: rowSize } = useContext<{ gutter: TdRowProps['gutter']; size?: string }>(RowContext);

  const classPrefix = 't';
  const allSizes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
  const sizeClasses = allSizes.reduce((acc, currSize) => {
    const sizeProp = (props as Record<string, any>)[currSize];
    let sizeObj: any = {};
    if (typeof sizeProp === 'number') {
      sizeObj.span = sizeProp;
    } else if (isObject(sizeProp)) {
      sizeObj = sizeProp || {};
    }

    return {
      ...acc,
      [`${classPrefix}-col-${currSize}-${sizeObj.span}`]: sizeObj.span !== undefined,
      [`${classPrefix}-col-${currSize}-order-${sizeObj.order}`]: parseInt(sizeObj.order, 10) >= 0,
      [`${classPrefix}-col-${currSize}-offset-${sizeObj.offset}`]: parseInt(sizeObj.offset, 10) >= 0,
      [`${classPrefix}-col-${currSize}-push-${sizeObj.push}`]: parseInt(sizeObj.push, 10) >= 0,
      [`${classPrefix}-col-${currSize}-pull-${sizeObj.pull}`]: parseInt(sizeObj.pull, 10) >= 0,
    };
  }, {});

  const avgClassName = `t-col-average-${avg}`;

  const colClassNames = classNames(
    `${classPrefix}-col`,
    className,
    {
      [`${classPrefix}-col-${span}`]: span !== undefined,
      [`${classPrefix}-col-offset-${offset}`]: parseInt(offset as unknown as string, 10) >= 0,
      [`${classPrefix}-col-pull-${pull}`]: parseInt(pull as unknown as string, 10) >= 0,
      [`${classPrefix}-col-push-${push}`]: parseInt(push as unknown as string, 10) >= 0,
      [`${classPrefix}-col-order-${order}`]: parseInt(order as unknown as string, 10) >= 0,
    },
    sizeClasses,
    avg !== undefined ? avgClassName : null,
  );

  const colStyle: Record<string, any> = {
    ...calcColPadding(rowGutter, rowSize ?? ''),
    ...{
      width: avg !== undefined ? `${(1 / avg) * 100}%` : null,
    },
    ...propStyle,
  };

  if (flex) {
    const { flexGrow, flexShrink, flexBasis } = parseFlex(flex);
    colStyle.flexGrow = flexGrow;
    colStyle.flexShrink = flexShrink;
    colStyle.flexBasis = flexBasis;
  }

  return React.createElement(
    tag,
    {
      ref,
      className: colClassNames,
      style: colStyle,
      ...otherColProps,
    },
    children,
  );
});

Col.displayName = 'Col';

export default Col;
