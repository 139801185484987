import _ from 'lodash';
import {
  MouseEvent,
  FormEvent,
  CompositionEvent,
  useState,
  useCallback,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { Input, InputProps, InputRef, InputValue, Tooltip, TooltipProps } from 'tdesign-react';

interface IInputTooltipProps extends InputProps {
  /**
   * 输入框值发生变化时触发。参数 `trigger=initial` 表示传入的数据不符合预期，组件自动处理后触发 change 告知父组件。如：初始值长度超过 `maxlength` 限制
   * @param value
   * @param context
   * @returns
   */
  onChange?: (
    value: InputValue,
    context?: {
      e?: FormEvent<HTMLInputElement> | MouseEvent<any> | CompositionEvent<HTMLDivElement>;
      trigger: 'input' | 'initial' | 'clear';
      /**
       * 是否需要触发文字提示
       * @param value
       * @returns
       */
      triggerTooltip?: (props: TooltipProps) => void;
    },
  ) => void;
}

/**
 * 暴露InputTooltip Ref
 */
export interface IInputTooltipRef extends InputRef {
  setTooltipProps: (props: TooltipProps) => void;
}

/**
 * 带有Tooltip的输入框组件
 * @param {IInputTooltipProps} props
 * @returns
 */
export const InputTooltip = forwardRef<IInputTooltipRef | undefined, IInputTooltipProps>(
  (props: IInputTooltipProps, ref) => {
    const { onChange } = props;
    const inputRef = useRef<InputRef>(null);
    const [tooltipProps, setTooltipProps] = useState<TooltipProps | undefined>();

    const triggerTooltip = useCallback(
      (p: TooltipProps) => {
        const newProps = tooltipProps ? { ..._.cloneDeep(tooltipProps), ...p } : p;

        setTooltipProps({ ...newProps, visible: true });

        if (newProps.duration !== undefined) {
          setTimeout(() => {
            setTooltipProps((prevProps) => ({ ...prevProps, visible: false }));
          }, newProps.duration);
        }
      },
      [tooltipProps],
    );

    useImperativeHandle(ref, () => {
      if (inputRef.current) {
        return {
          ...inputRef.current,
          setTooltipProps: triggerTooltip,
        };
      }
    });

    return (
      <Tooltip {...tooltipProps}>
        <Input
          ref={inputRef}
          {...props}
          onChange={(value, ctx) => {
            if (ctx?.trigger) {
              onChange?.(value, {
                ...ctx,
                triggerTooltip,
              });
            }
          }}
        />
      </Tooltip>
    );
  },
);
