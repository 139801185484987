import classNames from 'classnames';
import { isObject } from 'lodash';
import React, { createContext, forwardRef, useCallback, useEffect, useState } from 'react';
import { canUseDocument, getCssVarsValue } from '../_util/dom';
import { StyledProps } from '../common';
import { rowDefaultProps } from '../defaultProps';
import useDefaultProps from '../hooks/useDefaultProps';
import { TdRowProps } from '../type';

/**
 * Row 组件支持的属性。
 */
export interface RowProps extends TdRowProps, StyledProps {
  key?: string;
  tag?: keyof JSX.IntrinsicElements; // 确保 tag 是有效的 HTML 标签
  /**
   * 默认子元素内容
   */
  children?: JSX.IntrinsicElements['div']['children'];
}

export interface GutterObject {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}

export const RowContext = createContext<{ gutter: TdRowProps['gutter']; size?: string }>({
  gutter: undefined,
  size: undefined,
});

const calcSize = (width: number) => {
  const smWidth = parseFloat(getCssVarsValue('--td-screen-sm') || '768');
  const mdWidth = parseFloat(getCssVarsValue('--td-screen-md') || '992');
  const lgWidth = parseFloat(getCssVarsValue('--td-screen-lg') || '1200');
  const xlWidth = parseFloat(getCssVarsValue('--td-screen-xl') || '1400');
  const xxlWidth = parseFloat(getCssVarsValue('--td-screen-xxl') || '1880');

  let size = 'xs';
  if (width >= xxlWidth) {
    size = 'xxl';
  } else if (width >= xlWidth) {
    size = 'xl';
  } else if (width >= lgWidth) {
    size = 'lg';
  } else if (width >= mdWidth) {
    size = 'md';
  } else if (width >= smWidth) {
    size = 'sm';
  } else {
    size = 'xs';
  }

  return size;
};

const calcRowStyle = (gutter: TdRowProps['gutter'], currentSize: string): object => {
  const rowStyle: any = {};
  if (typeof gutter === 'number') {
    Object.assign(rowStyle, {
      marginLeft: `${gutter / -2}px`,
      marginRight: `${gutter / -2}px`,
    });
  } else if (Array.isArray(gutter) && gutter.length) {
    if (typeof gutter[0] === 'number') {
      const gutterArr = gutter as number[];
      Object.assign(rowStyle, {
        marginLeft: `${gutterArr[0] / -2}px`,
        marginRight: `${gutterArr[0] / -2}px`,
      });
    }
    if (typeof gutter[1] === 'number') {
      const marginBottom = `${0 - gutter[1]}px`;
      Object.assign(rowStyle, { marginBottom });
      // pix环境不支持row-gap属性，所以给每个Col组件添加了padding-bottom，实现换行
      // 虽然padding-bottom实现换行，但最后一行的Col就会多出一行的gutter[1]的高度，所以上面把Row的marginBottom减去这个gutter[1]的高度
      // 保证了Row组件整体高度符合预期不会多出一行gutter[1]的高度
      // Object.assign(rowStyle, { rowGap: `${gutter[1] as number}px` });
    }
    const gutter0 = gutter[0] as Record<string, any>;
    if (isObject(gutter0) && gutter0[currentSize] !== undefined) {
      Object.assign(rowStyle, {
        marginLeft: `${gutter0[currentSize] / -2}px`,
        marginRight: `${gutter0[currentSize] / -2}px`,
      });
    }
    const gutter1 = gutter[1] as Record<string, any>;
    if (isObject(gutter1) && gutter1[currentSize] !== undefined) {
      const marginBottom = `${0 - gutter1[currentSize]}px`;
      Object.assign(rowStyle, { marginBottom });
      // pix环境不支持row-gap属性，所以给每个Col组件添加了padding-bottom，实现换行
      // 虽然padding-bottom实现换行，但最后一行的Col就会多出一行的gutter[1]的高度，所以上面把Row的marginBottom减去这个gutter[1]的高度
      // 保证了Row组件整体高度符合预期不会多出一行gutter[1]的高度
      // Object.assign(rowStyle, { rowGap: `${gutter1[currentSize]}px` });
    }
  } else if (isObject(gutter) && (gutter as Record<string, any>)[currentSize]) {
    const gutterObj = gutter as Record<string, any>;
    if (Array.isArray(gutterObj[currentSize]) && gutterObj[currentSize].length) {
      Object.assign(rowStyle, {
        marginLeft: `${gutterObj[currentSize][0] / -2}px`,
        marginRight: `${gutterObj[currentSize][0] / -2}px`,
      });
      const marginBottom = `${0 - gutterObj[currentSize][1]}px`;
      Object.assign(rowStyle, { marginBottom });
      // pix环境不支持row-gap属性，所以给每个Col组件添加了padding-bottom，实现换行
      // 虽然padding-bottom实现换行，但最后一行的Col就会多出一行的gutter[1]的高度，所以上面把Row的marginBottom减去这个gutter[1]的高度
      // 保证了Row组件整体高度符合预期不会多出一行gutter[1]的高度
      // Object.assign(rowStyle, { rowGap: `${gutterObj[currentSize][1]}px` });
    } else {
      Object.assign(rowStyle, {
        marginLeft: `${gutterObj[currentSize] / -2}px`,
        marginRight: `${gutterObj[currentSize] / -2}px`,
      });
    }
  }
  return rowStyle;
};

const Row = forwardRef<HTMLElement, RowProps>((props, ref) => {
  const {
    align,
    gutter,
    justify,
    tag = 'div',
    style: propStyle,
    className,
    children,
    ...otherRowProps
  } = useDefaultProps<RowProps>(props, rowDefaultProps);

  const [size, setSize] = useState(canUseDocument ? calcSize(window.innerWidth) : 'md');

  const updateSize = useCallback(() => {
    const currentSize = calcSize(window.innerWidth);
    if (currentSize !== size) {
      setSize(currentSize); // 修正这里，应该更新为 currentSize
    }
  }, [size]);

  const classPrefix = 't';
  const rowClassNames = classNames(`${classPrefix}-row`, className, {
    [`${classPrefix}-row--${justify}`]: true,
    [`${classPrefix}-row--${align}`]: true,
  });
  const rowStyle = {
    ...calcRowStyle(gutter, size),
    ...propStyle,
  };

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [size, updateSize]); // 添加 size 作为依赖项

  return React.createElement(
    tag,
    {
      ref,
      className: rowClassNames,
      style: rowStyle,
      ...otherRowProps,
    },
    <RowContext.Provider value={{ gutter, size }}>{children}</RowContext.Provider>,
  );
});

Row.displayName = 'Row';

export default Row;
