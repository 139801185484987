import './index.less';
import { CheckCircleFilledIcon, CircleIcon } from 'tdesign-icons-react';

interface ICusRadioProps {
  onChange?: (value?: string | number | boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  value?: string | number | boolean;
  children?: JSX.Element;
}

/**
 * 自定义单选组件
 * @param {ICusRadioProps} props
 * @returns
 */
const CusRadio = ({ onChange, checked, children }: ICusRadioProps) => {
  const handleClick = () => {
    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <label className="cus-radio" onClick={handleClick}>
      <span>
        {checked ? (
          <CheckCircleFilledIcon className="fs-20px cus-radio-selected" />
        ) : (
          <CircleIcon className="fs-20px c-666" />
        )}
      </span>
      {children && <span className="cus-radio-text">{children}</span>}
    </label>
  );
};

export default CusRadio;
