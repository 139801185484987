import { Col, Row } from '@tux-meter-ui/h5/components/Grid';
import {
  EMetricType,
  EQuestionItemType,
  IQuestionItem,
  IQuestionResItem,
  IQuestionSurveyModel,
} from '@tux-meter-ui/h5/interface/survey';
import { HtmlHelper } from '@tux-meter-ui/h5/utils/html-helper';
import { useRoute, useRouter } from '@tux-meter-ui/h5/utils/router-helper';
import { getTuxSurveyConfigByLegacy } from '@tux-meter-ui/h5/utils/survey-helper';
import { ISubmitValidateResult, TuxMeterUI } from '@tux-meter-ui/index';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MessagePlugin, Progress } from 'tdesign-react';
import './index.less';

interface QuestionPreviewerProps {
  questions: IQuestionItem[];
}

/**
 * 问卷预览器
 */
const QuestionPreviewer: React.FC<QuestionPreviewerProps> = ({ questions }) => {
  const route = useRoute();
  const router = useRouter();
  const [displayQuestions, setDisplayQuestions] = useState<IQuestionResItem[]>([]);
  const [surveyEle, setSurveyEle] = useState<HTMLElement | undefined>();
  // 使用 useRef 存储 getTuxNativeBaseSurveyView 返回的 submit 函数
  const tuxMeterSubmitFnRef = useRef<() => Promise<ISubmitValidateResult | undefined>>();
  const surveyPanelRef = useRef<HTMLDivElement | null>(null); // 修改为 HTMLDivElement

  const title = useMemo(() => {
    const title = route.query.title ?? '';
    return _.isString(title) && title.length > 0 ? `问卷预览-${title}` : '问卷预览';
  }, [route.query.title]);

  useEffect(() => {
    HtmlHelper.setTitle(title);
  }, [title]);

  const legacy: IQuestionSurveyModel | undefined = useMemo(() => {
    if (questions.length > 0) {
      return {
        title,
        index_group_id: '',
        create_time: '',
        last_modified_by: '',
        update_time: '',
        smart_mode: true,
        metric: EMetricType.CSAT,
        pages: [
          {
            id: '1',
            questions,
          },
        ],
      };
    }
    return undefined;
  }, [title, questions]);

  // 构建 TuxMeterUI.getTuxNativeBaseSurveyView 函数需要的参数
  const tuxSurveyConfig = useMemo(() => {
    if (legacy) {
      return getTuxSurveyConfigByLegacy(legacy);
    }
    return undefined;
  }, [legacy]);

  useEffect(() => {
    if (tuxSurveyConfig) {
      const { surveyEle, submit } = TuxMeterUI.getTuxNativeBaseSurveyView({
        surveyConfig: tuxSurveyConfig,
        setting: {},
        starOptionId: '',
        tuxEventListener: {
          onDisplayQuestionsChange: (displayQuestions) => {
            setDisplayQuestions(displayQuestions);
          },
        },
      });
      // 获取嵌入问卷元素
      setSurveyEle(surveyEle);
      tuxMeterSubmitFnRef.current = submit;
    }
  }, [tuxSurveyConfig]);

  useEffect(() => {
    if (surveyPanelRef.current && surveyEle) {
      surveyPanelRef.current.replaceChildren(surveyEle);
    }
  }, [surveyEle]);

  const progressEle = () => {
    const total = displayQuestions.filter((c) => c.model.required).length;
    const count = displayQuestions.reduce((res: number, item) => {
      if (item.model.required) {
        const { type, value } = item;
        if (type === EQuestionItemType.Star || type === EQuestionItemType.Option) {
          if (value.length > 0) {
            return res + 1;
          }
        }
        if (type === EQuestionItemType.Text) {
          if (value.length !== 0 && value !== undefined) {
            return res + 1;
          }
        }
      }
      return res;
    }, 0);
    const percentage = (count / total) * 100;

    return (
      <Progress
        theme="line"
        percentage={percentage}
        strokeWidth="3px"
        label={null}
        trackColor="#C2DAFF"
        color="#0A6CFF"
      />
    );
  };

  const submit = async () => {
    const validateResult = await tuxMeterSubmitFnRef.current?.();
    if (validateResult === undefined) {
      MessagePlugin.warning('验证失败');
    }
    console.log('validateResult:', validateResult);
    const { validate } = validateResult ?? {};
    // 验证问题填答失败的情况
    if (!validate) {
      return false;
    }
    router.replace({
      name: 'SurveySuccessView',
    });
  };

  return (
    <div className="question-previewer-panel h-100-p flex-columns">
      <div className="flex-none">
        <Row className="h-10px w-100-p" align="middle" style={{ background: '#F0F6FF' }}>
          <Col span={12} className="pl-24px pr-24px">
            {progressEle()}
          </Col>
        </Row>
      </div>
      <div className="flex-1 pl-24px pr-24px pt-16px" style={{ overflowY: 'auto' }} ref={surveyPanelRef}></div>
      <div className="flex-none pl-24px pr-24px pt-16px pb-48px">
        <div className="submit" onClick={submit}>
          <Row className="w-100-p h-100-p" justify="center" align="middle">
            <Col className="c-fff fs-14px">提交</Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default QuestionPreviewer;
