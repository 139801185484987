import { Col, Row } from '@tux-meter-ui/components/Grid';
import { useSize } from 'ahooks';
import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect, useCallback, useMemo } from 'react';
import { animated, useSpring } from 'react-spring';
import { ChevronDownIcon, ChevronUpIcon } from 'tdesign-icons-react';
import './index.less'; // 引入样式文件

export enum EScreenModalType {
  /**
   * 全屏
   */
  FULL = 'full',
  /**
   * 半屏
   */
  HALF = 'half',
  /**
   * mini收起状态
   */
  MINI = 'MINI',
}

export enum EExpandedType {
  /**
   * 减少
   */
  LESSEN = 'LESSEN',
  /**
   * 增大
   */
  INCREASE = 'INCREASE',
}

interface IScreenModalProps {
  visible?: boolean;
  type?: EScreenModalType;
  onClose?: (visible: boolean) => boolean | undefined | void;
  children?: JSX.Element;
  modalClassName?: string;
}

export const ScreenModal = forwardRef(
  ({ visible, type = EScreenModalType.HALF, onClose, children, modalClassName }: IScreenModalProps, ref) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const size = useSize(modalRef);
    const minHeight = 20;
    const [open, setOpen] = useState(visible ?? false);
    const [modalType, setModalType] = useState(type);
    const [expandedType, setExpandedType] = useState(EExpandedType.LESSEN);

    useImperativeHandle(ref, () => ({
      show: () => setOpen(true),
      hide: () => setOpen(false),
    }));

    const [{ height }, set] = useSpring(() => ({
      height: open ? size?.height : 0,
      config: { tension: 300, friction: 30 },
    }));

    const handleOverlayClick = (e: React.MouseEvent) => {
      if (e.target instanceof Element && e.target.closest('.tux-screen-modal')) {
        return;
      }
      const fnResult = onClose?.(false);
      const result = fnResult !== undefined ? fnResult : false;
      setOpen(result);
    };

    const isDownIcon = useMemo(() => {
      switch (modalType) {
        case EScreenModalType.FULL:
          return true;
        case EScreenModalType.HALF:
          return expandedType === EExpandedType.LESSEN;
        default:
          return false;
      }
    }, [modalType, expandedType]);

    const toggleExpand = useCallback(() => {
      switch (modalType) {
        case EScreenModalType.FULL:
          setModalType(EScreenModalType.HALF);
          // 如果已经是最大了，则开始缩小
          if (expandedType === EExpandedType.INCREASE) {
            setExpandedType(EExpandedType.LESSEN);
          }
          break;
        case EScreenModalType.HALF:
          // 如果是缩小则继续调整模态窗类型，反之则全屏
          setModalType(expandedType === EExpandedType.LESSEN ? EScreenModalType.MINI : EScreenModalType.FULL);
          break;
        default:
          // MINI
          setModalType(EScreenModalType.HALF);
          // 如果已经是最小了，则开始增大
          if (expandedType === EExpandedType.LESSEN) {
            setExpandedType(EExpandedType.INCREASE);
          }
          break;
      }
    }, [modalType, expandedType]);

    const modalHeight = useMemo(() => {
      const height = size?.height ?? 0;
      switch (modalType) {
        case EScreenModalType.FULL:
          return height;
        case EScreenModalType.HALF:
          return height / 2;
        default:
          return minHeight;
      }
    }, [modalType, size]);

    useEffect(() => {
      set({ height: modalHeight });
    }, [modalHeight, set]);

    return (
      <div ref={modalRef} className={`tux-overlay ${open ? 'open' : ''}`} onClick={handleOverlayClick}>
        <animated.div
          className={`tux-screen-modal ${type === EScreenModalType.HALF ? 'half' : 'full'} ${modalClassName ?? ''}`}
          style={{ height: height?.to((h) => `${h}px`), overflow: 'hidden' }} // 使用 height 控制高度
        >
          <div className="flex-columns" style={{ height: '100%' }}>
            <div className="flex-none" onClick={toggleExpand}>
              <Row align="center" justify="center" className="modal-header">
                <Col>
                  {isDownIcon ? (
                    <ChevronDownIcon className="block fs-24px" />
                  ) : (
                    <ChevronUpIcon className="block fs-24px" />
                  )}
                </Col>
              </Row>
            </div>
            <div className="flex-1 overflow-y-auto">{children}</div>
          </div>
        </animated.div>
      </div>
    );
  },
);
