import { Col, Row } from '@tux-meter-ui/components/Grid';
import { MobileModal } from '@tux-meter-ui/components/MobileModal';
import dayjs, { Dayjs, UnitType } from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import Picker from 'react-mobile-picker';
import { ChevronDownIcon, CloseCircleFilledIcon } from 'tdesign-icons-react';
import { Button } from 'tdesign-react';
import './index.less';

export type ITimePickerFields = 'hour' | 'minute' | 'second';

export interface IMobileDatePicker {
  /**
   * 用于格式化日期显示的格式，'YYYY-MM-DD HH:mm:ss'，最终影响onChange的value
   */
  format?: string;
  placeholder?: string;
  /**
   * 是否显示清除按钮
   */
  clearable?: boolean;
  onConfirm?: (value?: string) => void;
  onCancel?: () => void;
  value?: string;
  /**
   * 具体时间选择展示的字段（如果有小时则显示小时，如果有分钟则显示分钟，如果有秒则显示秒）
   */
  timePickerFields?: ITimePickerFields[];
  /**
   * 开始时间
   */
  startTime?: string;
  /**
   * 结束时间
   */
  endTime?: string;
  /**
   * 每列展示个数，默认为7
   */
  columnCount?: number;
}

/**
 * 每个元素的高度
 */
const ITEM_HEIGHT = 36;

/**
 * 校验时间是否在这个区间内
 * @param sameType 上一级时间是否一致
 * @param targetType
 * @param value
 * @param currDayjs
 * @param startDayjs
 * @param endDayjs
 * @returns
 */
function isInTimeRange(
  sameType: UnitType,
  targetType: UnitType,
  value: number,
  currDayjs: Dayjs,
  startDayjs?: Dayjs,
  endDayjs?: Dayjs,
) {
  if (startDayjs && startDayjs.isSame(currDayjs, sameType) && startDayjs.get(targetType) > value) {
    return true;
  }
  if (endDayjs && endDayjs.isSame(currDayjs, sameType) && endDayjs.get(targetType) < value) {
    return true;
  }
  return false;
}

/**
 * 移动端-时间选择器
 * @param {IMobileDatePicker} props
 * @returns
 */
export const MobileDatePicker: React.FC<IMobileDatePicker> = ({
  placeholder,
  clearable = false,
  format = 'YYYY-MM-DD HH:mm:ss',
  value,
  startTime,
  endTime,
  columnCount = 7,
  timePickerFields = [],
  onConfirm,
  onCancel,
}) => {
  const [pickerValue, setPickerValue] = useState({
    year: dayjs().get('year'),
    month: dayjs().get('month'),
    day: dayjs().get('date'),
    hour: dayjs().get('hour'),
    minute: dayjs().get('minute'),
    second: dayjs().get('second'),
  });

  const [visible, setVisible] = useState(false);

  const currDayjs = useMemo(() => {
    const { year, month, day, hour, minute, second } = pickerValue;
    const currDayjs = dayjs(
      new Date(
        year,
        // month 从 0 开始
        month,
        day,
        hour,
        minute,
        second,
      ),
    );
    return currDayjs;
  }, [pickerValue]);
  const startDayjs = useMemo(() => {
    if (!startTime) return;
    return dayjs(startTime, format);
  }, [startTime, format]);
  const endDayjs = useMemo(() => {
    if (!endTime) return;
    return dayjs(endTime, format);
  }, [endTime, format]);

  const years = useMemo(() => {
    const year = currDayjs.get('year');
    const firstYear = year - Math.floor(columnCount / 2);
    const startYear = startDayjs?.get('year');
    const endYear = endDayjs?.get('year');
    const years = Array.from({ length: columnCount }, (_, i) => firstYear + i);
    return years.reduce((res: number[], year) => {
      if (startYear && year < startYear) {
        return res;
      }
      if (endYear && year > endYear) {
        return res;
      }
      return [...res, year];
    }, []);
  }, [currDayjs, startDayjs, endDayjs, columnCount]);

  const months = useMemo(() => {
    const length = 12;
    // month 从 0 开始
    const arr = Array.from({ length }, (_, i) => i);
    return arr.reduce((res: number[], item) => {
      const pass = isInTimeRange('year', 'month', item, currDayjs, startDayjs, endDayjs);
      if (pass) {
        return res;
      }
      return [...res, item];
    }, []);
  }, [currDayjs, startDayjs, endDayjs]);

  const days = useMemo(() => {
    const length = currDayjs.daysInMonth();
    // day 从 1 开始
    const arr = Array.from({ length }, (_, i) => i + 1);
    return arr.reduce((res: number[], item) => {
      const pass = isInTimeRange('month', 'date', item, currDayjs, startDayjs, endDayjs);
      if (pass) {
        return res;
      }
      return [...res, item];
    }, []);
  }, [currDayjs, startDayjs, endDayjs]);

  const hours = useMemo(() => {
    const length = 24;
    // hour 从 1 开始
    const arr = Array.from({ length }, (_, i) => i);
    return arr.reduce((res: number[], item) => {
      const pass = isInTimeRange('date', 'hour', item, currDayjs, startDayjs, endDayjs);
      if (pass) {
        return res;
      }
      return [...res, item];
    }, []);
  }, [currDayjs, startDayjs, endDayjs]);

  const minutes = useMemo(() => {
    const length = 60;
    const arr = Array.from({ length }, (_, i) => i);
    return arr.reduce((res: number[], item) => {
      const pass = isInTimeRange('hour', 'minute', item, currDayjs, startDayjs, endDayjs);
      if (pass) {
        return res;
      }
      return [...res, item];
    }, []);
  }, [currDayjs, startDayjs, endDayjs]);

  const seconds = useMemo(() => {
    const length = 60;
    const arr = Array.from({ length }, (_, i) => i);
    return arr.reduce((res: number[], item) => {
      const pass = isInTimeRange('minute', 'second', item, currDayjs, startDayjs, endDayjs);
      if (pass) {
        return res;
      }
      return [...res, item];
    }, []);
  }, [currDayjs, startDayjs, endDayjs]);

  const pickPanelHeight = useMemo(() => columnCount * ITEM_HEIGHT, [columnCount]);

  useEffect(() => {
    const currDayjs = value ? dayjs(value, format) : dayjs();
    setPickerValue({
      year: currDayjs.get('year'),
      month: currDayjs.get('month'),
      day: currDayjs.get('date'),
      hour: currDayjs.get('hour'),
      minute: currDayjs.get('minute'),
      second: currDayjs.get('second'),
    });
  }, [value, format]);

  // 当pick的值不存在年月日等列表中的情况下，则重设pick值
  useEffect(() => {
    function setDefaultPickValue(
      field: 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second',
      val: number,
      arr: number[],
    ) {
      if (arr.indexOf(val) === -1) {
        setPickerValue((preVal) => {
          const newVal = { ...preVal };
          newVal[field] = arr[0];
          return newVal;
        });
      }
    }
    setDefaultPickValue('year', currDayjs.get('year'), years);
    setDefaultPickValue('month', currDayjs.get('month'), months);
    setDefaultPickValue('day', currDayjs.get('date'), days);
    setDefaultPickValue('hour', currDayjs.get('hour'), hours);
    setDefaultPickValue('minute', currDayjs.get('minute'), minutes);
    setDefaultPickValue('second', currDayjs.get('second'), seconds);
  }, [currDayjs, years, months, days, hours, minutes, seconds]);

  const timePickerFieldFormat = useMemo(() => {
    const hasHour = timePickerFields.indexOf('hour') > -1;
    const hasMinute = timePickerFields.indexOf('minute') > -1;
    const hasSecond = timePickerFields.indexOf('second') > -1;
    if (hasHour) {
      if (hasMinute) {
        if (hasSecond) {
          return 'YYYY-MM-DD HH:mm:ss';
        }
        return 'YYYY-MM-DD HH:mm';
      }
      return 'YYYY-MM-DD HH';
    }
    return undefined;
  }, [timePickerFields]);

  const timePickerFieldValueFormat = useMemo(() => {
    const hasHour = timePickerFields.indexOf('hour') > -1;
    const hasMinute = timePickerFields.indexOf('minute') > -1;
    const hasSecond = timePickerFields.indexOf('second') > -1;
    if (hasHour) {
      if (hasMinute) {
        if (hasSecond) {
          return 'YYYY-MM-DD HH:mm:ss';
        }
        return 'YYYY-MM-DD HH:mm:00';
      }
      return 'YYYY-MM-DD HH:00:00';
    }
    return 'YYYY-MM-DD 00:00:00';
  }, [timePickerFields]);

  const currPlaceholder = useMemo(() => {
    if (value) {
      const format = timePickerFieldFormat ?? 'YYYY-MM-DD';
      return dayjs(value).format(format);
    }
    return placeholder ?? '请选择';
  }, [value, timePickerFieldFormat, placeholder]);

  const columnHourEle = useMemo(() => {
    if (timePickerFields.indexOf('hour') > -1) {
      return (
        <Picker.Column name="hour">
          {hours.map((option) => (
            <Picker.Item key={option} value={option}>
              <span className={option === pickerValue.hour ? 'selected' : ''}>{option}</span>
            </Picker.Item>
          ))}
        </Picker.Column>
      );
    }
    return null;
  }, [timePickerFields, hours, pickerValue.hour]);
  const columnMinuteEle = useMemo(() => {
    if (timePickerFields.indexOf('minute') > -1) {
      return (
        <Picker.Column name="minute">
          {minutes.map((option) => (
            <Picker.Item key={option} value={option}>
              <span className={option === pickerValue.minute ? 'selected' : ''}>{option}</span>
            </Picker.Item>
          ))}
        </Picker.Column>
      );
    }
    return null;
  }, [timePickerFields, minutes, pickerValue.minute]);
  const columnSecondEle = useMemo(() => {
    if (timePickerFields.indexOf('second') > -1) {
      return (
        <Picker.Column name="second">
          {seconds.map((option) => (
            <Picker.Item key={option} value={option}>
              <span className={option === pickerValue.second ? 'selected' : ''}>{option}</span>
            </Picker.Item>
          ))}
        </Picker.Column>
      );
    }
    return null;
  }, [timePickerFields, seconds, pickerValue.second]);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = ''; // 恢复默认值
    }
    // 清理函数，在组件卸载时或在 `visible` 变化时执行
    return () => {
      document.body.style.overflow = '';
    };
  }, [visible]);

  return (
    <>
      <div
        onClick={() => {
          setVisible(true);
        }}
      >
        <Row justify="space-between" align="middle">
          <Col>
            <span>{currPlaceholder}</span>
          </Col>
          <Col>
            {clearable && value ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onConfirm?.();
                }}
              >
                <CloseCircleFilledIcon className="block fs-24px" />
              </div>
            ) : (
              <ChevronDownIcon className="block fs-24px" />
            )}
          </Col>
        </Row>
      </div>
      <MobileModal
        visible={visible}
        closeOnOverlayClick={false}
        modalClassName="mobile-date-picker-modal"
        onClose={(visible) => {
          setVisible(visible);
        }}
        header={() => {
          return (
            <div className="">
              <Row justify="space-between" align="middle">
                <Col>
                  <Button
                    theme="default"
                    variant="text"
                    onClick={() => {
                      setVisible(false);
                      onCancel?.();
                    }}
                  >
                    取消
                  </Button>
                </Col>
                <Col>
                  <Button
                    theme="primary"
                    variant="text"
                    onClick={() => {
                      setVisible(false);
                      const curr = currDayjs.clone();
                      // 如果开启了具体时分秒，则完整返回，如果是普通日期的情况下，则返回 00:00:00
                      const result = curr.format(timePickerFieldValueFormat);
                      onConfirm?.(result);
                    }}
                  >
                    确定
                  </Button>
                </Col>
              </Row>
            </div>
          );
        }}
      >
        <Row>
          <Col flex={1}>
            <div className="header-text">年</div>
          </Col>
          <Col flex={1}>
            <div className="header-text">月</div>
          </Col>
          <Col flex={1}>
            <div className="header-text">日</div>
          </Col>
          {timePickerFields.length > 0 ? (
            <>
              {columnHourEle !== null ? (
                <Col flex={1}>
                  <div className="header-text">时</div>
                </Col>
              ) : null}
              {columnMinuteEle !== null ? (
                <Col flex={1}>
                  <div className="header-text">分</div>
                </Col>
              ) : null}
              {columnSecondEle !== null ? (
                <Col flex={1}>
                  <div className="header-text">秒</div>
                </Col>
              ) : null}
            </>
          ) : null}
        </Row>
        <Picker
          value={pickerValue}
          onChange={(value) => {
            const { year, month, day, hour, minute, second } = value;
            let currDayjs = dayjs(
              new Date(
                year,
                // month 从 0 开始
                month,
                day,
                hour,
                minute,
                second,
              ),
            );
            // 用于校验当前年份和月份下天数
            const validateDate = dayjs(
              new Date(
                year,
                // month 从 0 开始
                month,
                // 传入1号
                1,
                hour,
                minute,
                second,
              ),
            );
            // 每个月1定有1号，所以设置为1号，获取当前年份-月份下所有天数
            const validateDayInMonth = validateDate.daysInMonth();
            // 当前选择的日期不合法时，将它设为最后一天
            if (validateDayInMonth !== currDayjs.daysInMonth()) {
              currDayjs = currDayjs.set('month', month);
              currDayjs = currDayjs.set('date', validateDayInMonth);
            }
            // 当选择后的时间早于开始时间，则将开始时间直接覆盖为选中时间
            if (startDayjs && currDayjs < startDayjs) {
              setPickerValue({
                year: startDayjs.get('year'),
                month: startDayjs.get('month'),
                day: startDayjs.get('date'),
                hour: startDayjs.get('hour'),
                minute: startDayjs.get('minute'),
                second: startDayjs.get('second'),
              });
              return;
            }
            // 当选择后的时间晚于结束时间，则将结束时间直接覆盖为选中时间
            if (endDayjs && currDayjs > endDayjs) {
              setPickerValue({
                year: endDayjs.get('year'),
                month: endDayjs.get('month'),
                day: endDayjs.get('date'),
                hour: endDayjs.get('hour'),
                minute: endDayjs.get('minute'),
                second: endDayjs.get('second'),
              });
              return;
            }
            setPickerValue({
              year: currDayjs.get('year'),
              month: currDayjs.get('month'),
              day: currDayjs.get('date'),
              hour: currDayjs.get('hour'),
              minute: currDayjs.get('minute'),
              second: currDayjs.get('second'),
            });
          }}
          height={pickPanelHeight}
          itemHeight={ITEM_HEIGHT}
        >
          <Picker.Column name="year">
            {years.map((option) => (
              <Picker.Item key={option} value={option}>
                <span className={option === pickerValue.year ? 'selected' : ''}>{option}</span>
              </Picker.Item>
            ))}
          </Picker.Column>
          <Picker.Column name="month">
            {months.map((option) => (
              <Picker.Item key={option} value={option}>
                <span className={option === pickerValue.month ? 'selected' : ''}>{option + 1}</span>
              </Picker.Item>
            ))}
          </Picker.Column>
          <Picker.Column name="day">
            {days.map((option) => (
              <Picker.Item key={option} value={option}>
                <span className={option === pickerValue.day ? 'selected' : ''}>{option}</span>
              </Picker.Item>
            ))}
          </Picker.Column>
          {timePickerFields.length > 0 ? (
            <>
              {columnHourEle}
              {columnMinuteEle}
              {columnSecondEle}
            </>
          ) : null}
        </Picker>
      </MobileModal>
    </>
  );
};
