import { useSize } from 'ahooks';
import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { animated, useSpring } from 'react-spring';
import './index.less'; // 引入样式文件

interface IMobileModalProps {
  visible?: boolean;
  header?: JSX.Element | ((visible: boolean) => JSX.Element | null | undefined);
  height?: number | string;
  /**
   * 对话框挂载的节点。数据类型为 String 时，会被当作选择器处理，进行节点查询。示例：'body' 或 () => document.body
   */
  attach?: string | (() => HTMLElement);
  /**
   * 点击蒙层时是否触发关闭事件
   */
  closeOnOverlayClick?: boolean;
  onClose?: (visible: boolean) => boolean | undefined | void;
  children?: JSX.Element | JSX.Element[];
  modalClassName?: string;
}

export const MobileModal = forwardRef(
  (
    {
      visible,
      header,
      attach = 'body',
      closeOnOverlayClick = true,
      onClose,
      children,
      modalClassName,
    }: IMobileModalProps,
    ref,
  ) => {
    const modalContentRef = useRef<HTMLDivElement>(null);
    const size = useSize(modalContentRef);
    const [open, setOpen] = useState(visible ?? false);

    useImperativeHandle(ref, () => ({
      show: () => setOpen(true),
      hide: () => setOpen(false),
    }));

    const [{ height }, set] = useSpring(() => ({
      height: 0,
      config: { tension: 300, friction: 30 },
    }));

    const handleOverlayClick = (e: React.MouseEvent) => {
      // 点击蒙层时不需要触发关闭事件
      if (!closeOnOverlayClick) {
        return;
      }
      // 如果点击的内容区，则不关闭遮罩层
      if (e.target instanceof Element && e.target.closest('.mobile-modal-main')) {
        return;
      }
      const fnResult = onClose?.(!open);
      const result = fnResult !== undefined ? fnResult : false;
      setOpen(result);
    };

    const contentHeight = useMemo(() => {
      const height = size?.height ?? 0;
      return height;
    }, [size]);

    useEffect(() => {
      set({ height: contentHeight });
    }, [contentHeight, set]);

    useEffect(() => {
      if (visible !== undefined) {
        setOpen(visible);
      }
    }, [visible]);

    const headerEle = useMemo(() => {
      if (!header) {
        return null;
      }
      if (typeof header === 'function') {
        return header(visible ?? false);
      }
      return header;
    }, [header, visible]);

    const attachElement = useMemo(() => {
      if (typeof attach === 'string') {
        return document.querySelector(attach) || document.body;
      }
      if (typeof attach === 'function') {
        return attach() || document.body;
      }
      return document.body;
    }, [attach]);

    const modalContent = (
      <div className={`mobile-modal-overlay ${open ? 'open' : ''}`} onClick={handleOverlayClick}>
        <animated.div
          className="mobile-modal-main"
          style={{ height: height?.to((h) => `${h}px`), overflow: 'hidden' }} // 使用 height 控制高度
        >
          <div ref={modalContentRef} className={modalClassName}>
            <div className="mobile-modal-header">{headerEle}</div>
            <div className="mobile-modal-body">{children}</div>
          </div>
        </animated.div>
      </div>
    );

    return createPortal(modalContent, attachElement);
  },
);
