import { forwardRef } from 'react';
import { Icon } from 'tdesign-icons-react';
import './index.less';

interface ICusCheckboxProps {
  onChange?: (value: boolean) => void;
  onClick?: () => void;
  checked?: boolean;
  disabled?: boolean;
  value?: string | number | boolean;
  children?: JSX.Element;
}

/**
 * Checkbox选择组件
 * @param props
 * @returns
 */
export const CusCheckbox = forwardRef<HTMLLabelElement, ICusCheckboxProps>(
  ({ onChange, onClick, checked, children }, ref) => {
    const handleClick = () => {
      if (onChange) {
        onChange(!checked);
      }
      if (onClick) {
        onClick();
      }
    };

    return (
      <label ref={ref} className="cus-checkbox" onClick={handleClick}>
        <span>
          {checked ? (
            <Icon name="check-rectangle" className="fs-20px cus-checkbox-selected cus-checkbox-icon" />
          ) : (
            <Icon name="rectangle" className="fs-20px c-666 cus-checkbox-icon" />
          )}
        </span>
        {children && <span className="cus-checkbox-text">{children}</span>}
      </label>
    );
  },
);

export default CusCheckbox;
