/**
 * 验证错误事件
 */
export type IOnValidateError<T extends string> = (args: IOnValidateErrorArgs<T>) => void;

/**
 * 验证错误事件参数
 */
export interface IOnValidateErrorArgs<T extends string> {
  /**
   * 错误提示的元素
   */
  element?: HTMLElement;
  /**
   * 提示的内容
   */
  message: string;
  type: T;
}

/**
 * 验证错误类型
 */
export enum EValidateErrorType {
  /**
   * 问题级别，需要直接滚动并定位到题目处
   */
  QUESTION = 'question',
  /**
   * 题目级别，需要在题目后显示错误
   */
  TITLE = 'title',
  /**
   * tip提示，为验证错误的元素展示tips
   */
  TIP = 'tip',
}

export type IPopupPlacement =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | 'left-top'
  | 'left-bottom'
  | 'right-top'
  | 'right-bottom';

/**
 * 问题验证错误对象
 */
export interface IQuestionItemValidateError {
  /**
   * 验证信息
   */
  message: string;
  /**
   * 错误提示的元素（用于定位跳转或错误tips）
   */
  element?: HTMLElement | null;
  /**
   * 提示定位
   */
  placement?: IPopupPlacement;
  /**
   * 验证的错误提示类型（是否整个题目提醒，还是触发change的元素添加tip）
   */
  errorType: EValidateErrorType;
}

export interface IQuestionItemValidateResult {
  /**
   * 验证是否通过
   */
  result: boolean;
  /**
   * 问题验证错误对象集合
   */
  errors: IQuestionItemValidateError[];
}

/**
 * 问题项的Ref引用
 */
export interface IQuestionItemRef {
  /**
   * 验证当前问题项是否合法
   * @returns
   */
  validate: () => IQuestionItemValidateResult;
}
