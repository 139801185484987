import { TuxMeterSDK, TuxSurveyConfig } from '@tencent/tux-meter-js-sdk-core';
import { CloseIcon } from 'tdesign-icons-react';
import { ITuxEventListener } from '../../tux-meter-ui/inteface';
import { TuxNativeStarView } from '../TuxNativeStarView';
import './index.less';

interface IProps {
  surveyConfig: TuxSurveyConfig;
  tuxMeterSDKInstance: TuxMeterSDK;
  tuxEventListener: ITuxEventListener;
  onClose?: () => void;
}

/**
 * Tux弹窗问卷视图(component_type: 0)
 * @param {IProps} props
 * @returns
 */
export const TuxPopupSurveyView = (props: IProps) => {
  return (
    <div className="tux-popup-survey-view">
      {props.onClose && (
        <div
          className="close-icon"
          onClick={() => {
            props.onClose?.();
          }}
        >
          <CloseIcon className="c-assist-7 fs-20px block" />
        </div>
      )}
      <TuxNativeStarView {...props} />
    </div>
  );
};
