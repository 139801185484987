import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Tooltip, TooltipProps } from 'tdesign-react';

interface ITooltipProps extends TooltipProps {
  ele?: HTMLElement | null;
}

/**
 * Tooltip帮助类，使用该方法在指定位置弹出tooltip
 */
const TooltipPlugin = {
  error: (props: ITooltipProps) => {
    const { ele, duration, delay } = props;
    if (!ele) {
      return;
    }
    const div = document.createElement('div');
    ele.appendChild(div);

    const root = ReactDOM.createRoot(div);

    const removeTooltip = () => {
      root.unmount();
      div.remove();
    };

    const TooltipComponent = () => {
      const tooltipRef = useRef(null);

      useEffect(() => {
        if (duration) {
          const timer = setTimeout(
            () => {
              removeTooltip();
            },
            duration + (delay || 0),
          );

          return () => clearTimeout(timer);
        }
      }, []);

      return <Tooltip ref={tooltipRef} {...props} zIndex={99} theme="danger" visible={true} />;
    };

    root.render(<TooltipComponent />);
  },
};

/**
 * 根据当前节点，添加影子节点（高度宽度和传入节点一致，并通过absolute），并返回影子节点
 * @param ele
 * @returns
 */
export function addShadowElement(ele: HTMLElement) {
  if (!ele) {
    throw new Error('The provided element is not valid.');
  }

  // 获取ele的样式
  const eleStyle = window.getComputedStyle(ele);

  // 创建一个新的div元素
  const sibling = document.createElement('div');

  // 设置新元素的样式
  sibling.style.position = 'absolute';
  sibling.style.top = `${parseInt(eleStyle.height) / 2}px`;
  sibling.style.left = '0px';
  sibling.style.width = eleStyle.width;
  sibling.style.height = eleStyle.height;
  sibling.style.zIndex = '99';

  // 设置新元素的父元素为ele的父元素
  const parent = ele.parentElement;
  if (!parent) {
    throw new Error('The provided element does not have a parent.');
  }

  // 插入新元素到ele的下面
  parent.insertBefore(sibling, ele.nextSibling);

  // 设置父元素的position为relative，以便新元素的absolute定位生效
  if (window.getComputedStyle(parent).position === 'static') {
    parent.style.position = 'relative';
  }

  return sibling;
}

export default TooltipPlugin;
