import { routes } from '@tux-meter-ui/h5/router';
import _ from 'lodash';
import queryString from 'query-string';
import { useLocation, useParams, useNavigate, Params, Path } from 'react-router-dom';
import useUrlState from './use-url-state';

export interface RouteRecord {
  path: string;
  regex: RegExp;
  name?: string;
  parent?: RouteRecord;
  matchAs?: string;
}

export interface Route {
  path: string;
  name?: string | null;
  hash: string;
  query: Record<string, string | (string | null)[]>;
  params: Readonly<Params<string>>;
  fullPath: string;
  matched: RouteRecord[]; // 这里可以根据你的需求定义更具体的类型
  redirectedFrom?: string;
  meta?: any; // 这里可以根据你的需求定义更具体的类型
}

export const useRoute = (): Route => {
  const location = useLocation();
  const params = useParams();
  const [query] = useUrlState({});

  // 这里可以根据你的路由配置来构建 matched 和其他字段
  const matched: RouteRecord[] = []; // 需要根据实际情况填充

  return {
    path: location.pathname,
    name: null, // 这里可以根据实际情况填充
    hash: location.hash,
    query,
    params,
    fullPath: location.pathname + location.search + location.hash,
    matched: matched,
    redirectedFrom: undefined, // 这里可以根据实际情况填充
    meta: undefined, // 这里可以根据实际情况填充
  };
};

type Dictionary<T> = { [key: string]: T };

export interface Location {
  name?: string;
  path?: string;
  search?: string;
  hash?: string;
  query?: Dictionary<string | (string | null)[] | null | undefined>;
  params?: Dictionary<string>;
  append?: boolean;
  replace?: boolean;
}

export type RawLocation = string | Location;

/**
 * 打平的路由信息
 */
export interface IFlatRouteInfo {
  name?: string;
  path: string;
  fullPath: string;
  parent?: IFlatRouteInfo;
  children: IFlatRouteInfo[];
  level: number;
}

export class ReactRouter {
  app: any; // 这里可以根据你的需求定义更具体的类型
  mode: string; // 这里可以根据你的需求定义更具体的类型
  currentRoute: Route;
  routes: IRouterComponent[];
  navigate: ReturnType<typeof useNavigate>;

  constructor(routes: IRouterComponent[], navigate: ReturnType<typeof useNavigate>, route: Route) {
    this.app = null; // 这里可以根据实际情况填充
    this.routes = routes;
    this.navigate = navigate;
    this.mode = 'history'; // 默认模式
    this.currentRoute = route; // 获取当前路由
  }

  getFlatRoutes(routes: IRouterComponent[], level: number, fullPath?: string): IFlatRouteInfo[] {
    const flatRoutes = routes.reduce((res: IFlatRouteInfo[], route) => {
      const { name, path, children } = route;
      const childFlatRoutes = this.getFlatRoutes(children ?? [], level + 1, path);
      const model: IFlatRouteInfo = {
        name,
        path,
        fullPath: fullPath ? `${fullPath}/${path}` : path,
        children: childFlatRoutes,
        level,
      };
      return [...res, model, ...childFlatRoutes];
    }, []);
    return flatRoutes;
  }

  get flatRoutes(): IFlatRouteInfo[] {
    const flatRoutes = this.getFlatRoutes(this.routes, 1);
    return flatRoutes;
  }

  private buildPath(route: IFlatRouteInfo, params?: Dictionary<string>): string {
    let path = route.fullPath;
    if (params) {
      Object.keys(params).forEach((key) => {
        path = path.replace(`:${key}`, params[key]);
      });
    }
    return path;
  }

  private buildQuery(query?: Dictionary<string | (string | null)[] | null | undefined>): string {
    if (!query) return '';
    const queryStringified = queryString.stringify(query, { arrayFormat: 'bracket' });
    return queryStringified ? `?${queryStringified}` : '';
  }

  private findRoute(location: Location): IFlatRouteInfo | undefined {
    if (location.name) {
      return this.flatRoutes.find((route) => route.name === location.name);
    }
    if (location.path) {
      return this.flatRoutes.find((route) => route.fullPath === location.path);
    }
    return undefined;
  }

  getNavigateTo(to: RawLocation) {
    if (_.isString(to)) {
      return to;
    }
    const { params, query, hash } = to;
    const route = this.findRoute(to);
    if (!route) {
      throw new Error('No matching route found');
    }
    const newPath = this.buildPath(route, params);
    const newQuery = this.buildQuery(query);
    const res: Path = {
      pathname: newPath,
      search: newQuery,
      hash: hash ?? '',
    };
    console.log('newPath:', newPath);
    console.log('newQuery:', newQuery);
    console.log('res:', res);
    return res;
  }

  push(to: RawLocation) {
    const newToParams = this.getNavigateTo(to);
    this.navigate(newToParams, { replace: true });
  }

  replace(to: RawLocation) {
    const newToParams = this.getNavigateTo(to);
    this.navigate(newToParams, { replace: true });
  }

  go(n: number) {
    window.history.go(n);
  }

  back() {
    window.history.back();
  }

  forward() {
    window.history.forward();
  }

  // 其他方法可以根据需要实现
}

export const useRouter = () => {
  const navigate = useNavigate();
  const route = useRoute();
  return new ReactRouter(routes, navigate, route);
};

export interface IRouterComponent {
  name?: string;
  path: string;
  component: React.ReactNode;
  children?: IRouterComponent[];
}
