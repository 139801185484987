import { useEffect, useRef } from 'react';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import './index.less';

interface IImageViewerProps {
  imageUrl: string;
  renderContent?: () => JSX.Element;
  children?: JSX.Element;
}

/**
 * 图片预览组件
 * @param {IImageViewerProps} props
 * @returns
 */
export const ImageViewer = ({ imageUrl, renderContent, children }: IImageViewerProps) => {
  const imageContainerRef = useRef<HTMLDivElement | null>(null);
  const viewerRef = useRef<Viewer | null>(null);

  const openViewer = () => {
    if (viewerRef.current) {
      viewerRef.current.show();
    }
  };

  useEffect(() => {
    if (imageContainerRef.current) {
      viewerRef.current = new Viewer(imageContainerRef.current, {
        inline: false,
        navbar: false,
        toolbar: {
          zoomIn: false,
          zoomOut: false,
          oneToOne: false,
          reset: true,
          prev: false,
          play: false,
          next: false,
          rotateLeft: true,
          rotateRight: true,
          flipHorizontal: true,
          flipVertical: true,
        },
        viewed() {
          // viewerRef.current?.zoomTo(1);
        },
      });
    }

    return () => {
      if (viewerRef.current) {
        viewerRef.current.destroy();
        viewerRef.current = null;
      }
    };
  }, []);

  return (
    <div ref={imageContainerRef} className="image-viewer-container" onClick={openViewer}>
      {children ? children : renderContent && renderContent()}
      <img src={imageUrl} style={{ display: 'none' }} />
    </div>
  );
};
