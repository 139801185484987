/**
 * 实现URLSearchParams对象（兼容非浏览器环境的情况）
 */
export class SimpleURLSearchParams {
  private params: Map<string, string[]>;

  constructor(init?: string | Record<string, string> | [string, string][]) {
    this.params = new Map<string, string[]>();

    if (typeof init === 'string') {
      this.parseString(init);
    } else if (Array.isArray(init)) {
      for (const [key, value] of init) {
        this.append(key, value);
      }
    } else if (init && typeof init === 'object') {
      for (const key in init) {
        if (Object.prototype.hasOwnProperty.call(init, key)) {
          this.append(key, init[key]);
        }
      }
    }
  }

  private parseString(queryString: string): void {
    const pairs = queryString.replace(/^\?/, '').split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=').map(decodeURIComponent);
      this.append(key, value);
    }
  }

  append(name: string, value: string): void {
    if (!this.params.has(name)) {
      this.params.set(name, []);
    }
    this.params.get(name)!.push(value);
  }

  delete(name: string): void {
    this.params.delete(name);
  }

  get(name: string): string | null {
    const values = this.params.get(name);
    return values ? values[0] : null;
  }

  getAll(name: string): string[] {
    return this.params.get(name) || [];
  }

  has(name: string): boolean {
    return this.params.has(name);
  }

  set(name: string, value: string): void {
    this.params.set(name, [value]);
  }

  toString(): string {
    const pairs: string[] = [];
    for (const [key, values] of this.params) {
      for (const value of values) {
        pairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
    return pairs.join('&');
  }

  forEach(callback: (value: string, key: string, parent: SimpleURLSearchParams) => void, thisArg?: any): void {
    for (const [key, values] of this.params) {
      for (const value of values) {
        callback.call(thisArg, value, key, this);
      }
    }
  }
}

/**
 * 为URL添加query args
 * @param {string} url 原始URL
 * @param {{ key: string; value: string }[]} params 扩展参数
 * @returns
 */
export function addQueryArgs(url: string, params: { key: string; value: string }[]): string {
  const hashFragmentIndex = url.indexOf('#');
  const queryIndex = url.indexOf('?');
  // 如果存在#和?，并且#在?后面，那么先分割#,再分割?
  if (hashFragmentIndex > -1 && queryIndex > -1 && hashFragmentIndex > queryIndex) {
    const [fragmentLeftPath, hashFragment] = url.split('#');
    const [baseUrl, queryString] = fragmentLeftPath.split('?');
    // 创建一个URLSearchParams对象来处理现有的查询参数
    const searchParams = new SimpleURLSearchParams(queryString);
    // 添加新的参数
    params.forEach((param) => {
      searchParams.append(param.key, param.value);
    });
    // 重新构建URL
    const newQueryString = searchParams.toString();
    const result = newQueryString ? `${baseUrl}?${newQueryString ?? ''}` : baseUrl;
    return hashFragment ? `${result}#${hashFragment}` : result;
  }
  const [queryLeftUrl, queryString] = url.split('?');
  const [baseUrl, hashFragment] = queryLeftUrl.split('#');
  const hasFragment = hashFragmentIndex > -1;
  const result = hasFragment ? `${baseUrl}#${hashFragment ?? ''}` : baseUrl;
  // 创建一个URLSearchParams对象来处理现有的查询参数
  const searchParams = new SimpleURLSearchParams(queryString);
  // 添加新的参数
  params.forEach((param) => {
    searchParams.append(param.key, param.value);
  });
  // 重新构建URL
  const newQueryString = searchParams.toString();
  return newQueryString ? `${result}?${newQueryString}` : result;
}
