import {
  DisappearReason,
  ETuxSurveyType,
  ISubmitSurveyBasePayload,
  TuxSurveyConfig,
} from '@tencent/tux-meter-js-sdk-core';
import { IQuestionOptionItem, IQuestionResItem, IQuestionStar } from '@tux-meter-ui/interface/survey';

/**
 * 原生问卷设置
 */
export interface ITuxNativeSurveySetting {
  isDarkMode?: boolean;
  isFullScreen?: boolean;
  /**
   * 一道题并且是单选题的情况下自动提交
   */
  isAlone?: boolean;
}

/**
 * Tux事件回调参数
 */
export interface ITuxEventListenerCallbackBaseParams {
  surveyConfig: TuxSurveyConfig;
}

/**
 * Tux问卷关闭回调参数
 */
export interface ITuxEventListenerOnSurveyCloseParams extends ITuxEventListenerCallbackBaseParams {
  /**
   * 丢失原因
   */
  reason: DisappearReason;
}

export interface ITuxEventListenerOnSurveySubmitParams extends ITuxEventListenerCallbackBaseParams {
  /**
   * 当存在submitAnswerParams时，需要调用TuxMeterSDK实例的submitAnswer函数进行提交填答数据
   */
  submitAnswerParams?: {
    /**
     * 准备提交给后端的结果
     */
    payload: ISubmitSurveyBasePayload;
    /**
     * Tux问卷UI类型
     */
    tuxSurveyType: ETuxSurveyType;
  };
}

/**
 * Tux事件-量表题点击回调参数
 */
export interface IOnStarQuestionClickParams extends ITuxEventListenerCallbackBaseParams {
  /**
   * 量表题问题对象
   */
  question: IQuestionStar;
  /**
   * 点击的具体选项对象
   */
  option: IQuestionOptionItem;
}

/**
 * 问卷关闭时的视图类型
 */
export enum ESurveyCloseViewType {
  /**
   * 量表题
   */
  STAR = 'STAR',
  /**
   * 问卷
   */
  SURVEY = 'SURVEY',
}

/**
 * ui事件监听
 */
export interface ITuxEventListener {
  /**
   * 问卷展示时回调
   * @param {ITuxEventListenerCallbackBaseParams} params
   */
  onSurveyDisplayed?(params: ITuxEventListenerCallbackBaseParams): void;

  /**
   * 如果有量表题，用户点击量表题回调
   * @param {IOnStarQuestionClickParams} params
   */
  onStarQuestionClick?(params: IOnStarQuestionClickParams): void;

  /**
   * 问题点击回调
   * @param params
   */
  onQuestionClick?(params: ITuxEventListenerCallbackBaseParams): void;

  /**
   * 展示的问题变更回调
   * @param {IQuestionResItem} displayQuestions 当前展示的问题列表
   */
  onDisplayQuestionsChange?(displayQuestions: IQuestionResItem[]): void;

  /**
   * 问卷弹窗或者view关闭时回调
   * @param {ITuxEventListenerOnSurveyCloseParams} params
   * @param {ESurveyCloseViewType} viewType 视图类型，用于区分是触发关闭了量表题或是问卷主体
   */
  onSurveyClose?(params: ITuxEventListenerOnSurveyCloseParams, viewType: ESurveyCloseViewType): void;
  /**
   * 用户点击提交时回调
   * @param {ITuxEventListenerOnSurveySubmitParams} params
   */
  onSurveySubmit?(params: ITuxEventListenerOnSurveySubmitParams): void;
}

export interface IShowTuxCommonSurveyDialogParams {
  /**
   * TuxMeterWebSDK中返回的TuxSurveyConfig对象
   */
  surveyConfig: TuxSurveyConfig;
  /**
   * 量表题绑定的节点
   */
  starElement: HTMLElement;
  /**
   * 原生问卷设置
   */
  setting: ITuxNativeSurveySetting;
  tuxEventListener?: ITuxEventListener;
}

export interface IShowTuxWebViewSurveyDialogParams extends IShowTuxCommonSurveyDialogParams {
  /**
   * 默认量表题选中的选项ID
   */
  starOptionId?: string;
}

export type IGetH5SurveyElementParams = Omit<IShowTuxWebViewSurveyDialogParams, 'starElement'>;
