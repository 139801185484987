export const getRandomElements: <T>(arr: Array<T>, n: number) => Array<T> = (arr, n) => {
  // 创建数组的副本
  const shuffledArray = arr.slice();

  // Fisher-Yates 洗牌算法
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // 交换
  }

  return shuffledArray.slice(0, n); // 返回前 n 个元素
};
