import {
  EQuestionItemType,
  EQuestionOptionStatus,
  IQuestionItem,
  IQuestionMatrixResRow,
  IQuestionResItem,
  IQuestionResMatrix,
  IQuestionResOption,
  IQuestionResOptionItem,
  IQuestionResSensitiveInput,
  IQuestionResStar,
  IQuestionResText,
  IQuestionResTime,
} from '@tux-meter-ui/interface/survey';
import _ from 'lodash';

/**
 * 根据问题列表，生成提交需要的保存结果列表
 * @param questions
 */
export function getQuestionResItems(questions: IQuestionItem[]) {
  const res: IQuestionResItem[] = questions.reduce((res: IQuestionResItem[], question) => {
    switch (question.type) {
      case EQuestionItemType.Star: {
        // 量表题默认选中2分
        // const value = question.options?.filter(c => c.id === '2') ?? [];
        const model: IQuestionResStar = {
          type: question.type,
          model: question,
          value: [],
        };
        return [...res, model];
      }
      case EQuestionItemType.Option: {
        const model: IQuestionResOption = {
          type: question.type,
          model: question,
          value: [],
        };
        return [...res, model];
      }
      case EQuestionItemType.Text: {
        const model: IQuestionResText = {
          type: EQuestionItemType.Text,
          model: question,
          value: '',
        };
        return [...res, model];
      }
      case EQuestionItemType.Matrix: {
        const value: IQuestionMatrixResRow[] = question.sub_titles.map((sub_title) => {
          const { id, text } = sub_title;
          const options = question.options.map((option) => {
            const { id, text } = option;
            const optionRes: IQuestionResOptionItem = {
              id,
              text,
              status: EQuestionOptionStatus.UNCHOOSED,
            };
            return optionRes;
          });
          return {
            id,
            text,
            options,
          };
        });
        const model: IQuestionResMatrix = {
          type: EQuestionItemType.Matrix,
          model: question,
          value,
        };
        return [...res, model];
      }
      case EQuestionItemType.SensitiveInput: {
        const model: IQuestionResSensitiveInput = {
          type: EQuestionItemType.SensitiveInput,
          model: question,
          value: '',
          confirm: false,
        };
        return [...res, model];
      }
      case EQuestionItemType.Time: {
        const model: IQuestionResTime = {
          type: EQuestionItemType.Time,
          model: question,
          value: {
            time: question.submit_payload?.time ?? '',
            utc_offset: 8,
          },
        };
        return [...res, model];
      }
      default: {
        console.error(`getQuestionResItems 函数中没有匹配 ${(question as IQuestionResItem).type}`);
        return res;
      }
    }
  }, []);
  return res;
}

export interface IDisplayTreeNode {
  id: string;
  indexPath: number[];
  children: IDisplayTreeNode[];
}

/**
 * 获取拍平后的一维结构
 * @param nodes
 * @returns
 */
export function getFlattenTreeNode(nodes: IDisplayTreeNode[]) {
  const list: IDisplayTreeNode[] = nodes
    .filter((c) => c !== undefined)
    .reduce((res: IDisplayTreeNode[], item) => {
      return [...res, item, ...getFlattenTreeNode(item.children ?? [])];
    }, []);
  return list;
}

/**
 * 根据问题中的displays获取显示节点
 * @param questions
 * @returns
 */
export function getDisplayTreeNodesByQuestions(questions: IQuestionItem[]) {
  // 如果问题存在于任何一个选项中的显示逻辑(displays)中，则需要隐藏(不添加到res中)
  const nodes: IDisplayTreeNode[] = questions.reduce((res: IDisplayTreeNode[], question, index) => {
    const { id: itemId } = question;
    const questionIndex = questions.findIndex((c) => {
      // 有options的题型
      if (c.type === EQuestionItemType.Star || c.type === EQuestionItemType.Option) {
        const optionIndex = c.options.findIndex((o) => {
          const displayIndex = o.displays.findIndex((display) => display === itemId);
          return displayIndex > -1;
        });
        return optionIndex > -1;
      }
      return false;
    });
    // 不存在于任意一个displays中，常驻显示
    if (questionIndex === -1) {
      const displayTreeNode: IDisplayTreeNode = {
        id: itemId,
        indexPath: [index],
        children: [],
      };
      return [...res, displayTreeNode];
    }
    return res;
  }, []);
  return nodes;
}

/**
 * 传入元素或者选择器，移动滚动条并显示该元素
 * @param selectors
 */
export function scrollIntoView(selectors?: string | Element | null) {
  if (!selectors) {
    return;
  }
  const ele = _.isString(selectors) ? document.querySelector(selectors) : selectors;
  ele?.scrollIntoView({
    block: 'center',
    behavior: 'smooth',
  });
}
