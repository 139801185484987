import ImageSurveySuccess from '@tux-meter-ui/h5/assets/survey-success.png';
import { Col, Row } from '@tux-meter-ui/h5/components/Grid';
import './index.less';

/**
 * 问卷提交成功页
 */
function SurveySuccessView() {
  // 大于1024的情况，固定图片和文字大小
  const isPc = document.documentElement.clientWidth > 1024;

  return (
    <div className="survey-success-view">
      <Row className="w-100-p h-100-p" justify="center" align="middle">
        <Col>
          <Row justify="center">
            <Col>
              <img src={ImageSurveySuccess} style={{ width: isPc ? '200px' : '3rem' }} alt="" />
            </Col>
          </Row>
          <Row justify="center" style={{ marginTop: '12px' }}>
            <Col
              style={{
                fontSize: isPc ? '26px' : '20px',
              }}
            >
              问卷提交成功，感谢您的反馈！
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default SurveySuccessView;
