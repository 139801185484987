import { IRichNode } from '@tux-meter-ui/interface/survey';

interface Props {
  node: IRichNode;
}

/**
 * 根据富文本节点渲染html
 * @param {Props} props
 * @param {props.node} 富文本节点
 * @returns
 */
export const HtmlRender = ({ node }: Props) => {
  const { tag, attr, style, content, children } = node;

  const props: { [key: string]: string } = {};
  (attr ?? []).forEach((prop) => {
    props[prop.key] = prop.value;
  });

  const styles: { [key: string]: string } = {};
  (style ?? []).forEach((st) => {
    styles[st.key] = st.value;
  });

  if (tag === 'text') {
    return <span style={styles}>{content}</span>;
  }

  const Tag = tag as keyof JSX.IntrinsicElements;

  return (
    <Tag {...props} style={styles}>
      {(children ?? []).map((child, index) => (
        <HtmlRender key={index} node={child} />
      ))}
    </Tag>
  );
};
