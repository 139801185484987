import React from 'react';
import './index.less';

/**
 * Loading 组件
 */
const CusLoading: React.FC = () => {
  return <div className="cus-loading"></div>;
};

export default CusLoading;
