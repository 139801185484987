import { TuxSurveyConfig } from '@tencent/tux-meter-js-sdk-core';
import { Col, Row } from '@tux-meter-ui/components/Grid';
import {
  EMetricType,
  EQuestionItemType,
  IQuestionItem,
  IQuestionResItem,
  IQuestionSurveyModel,
} from '@tux-meter-ui/interface/survey';
import { convert2SurveyQuestions, isRadioQuestion } from '@tux-meter-ui/utils/survey-helper';
import { useState, useMemo, useRef, SetStateAction } from 'react';
import { Progress } from 'tdesign-react';
import { ITuxEventListener, ITuxNativeSurveySetting } from '../../tux-meter-ui/inteface';
import { SubmitButton } from '../SubmitButton';
import { ITuxNativeBaseSurveyViewRef, TuxNativeBaseSurveyView } from '../TuxNativeBaseSurveyView';
import './index.less';

interface IProps {
  surveyConfig: TuxSurveyConfig;
  setting: ITuxNativeSurveySetting;
  tuxEventListener?: ITuxEventListener;
  /**
   * 默认量表题选中的选项ID
   */
  starOptionId?: string;
}

/**
 * Tux原生完整问卷视图（包含进度条、默认展示提交按钮）
 * @param {IProps} props
 * @returns
 */
export const TuxNativeFullView = ({ surveyConfig, setting, tuxEventListener, starOptionId }: IProps) => {
  const tuxNativeBaseSurveyViewRef = useRef<ITuxNativeBaseSurveyViewRef>(null);
  const [displayQuestions, setDisplayQuestions] = useState<IQuestionResItem[]>([]);

  const progressEle = useMemo(() => {
    // 必填的总数
    const total = displayQuestions.filter((c) => c.model.required).length;
    const count = displayQuestions.reduce((res: number, item) => {
      if (item.model.required) {
        const { type, value } = item;
        if (type === EQuestionItemType.Star || type === EQuestionItemType.Option) {
          if (value.length > 0) {
            return res + 1;
          }
        }
        if (type === EQuestionItemType.Text) {
          if (value.length !== 0 && value !== undefined) {
            return res + 1;
          }
        }
      }
      return res;
    }, 0);
    const percentage = (count / total) * 100;
    return (
      <Progress
        theme="plump"
        percentage={percentage}
        strokeWidth="3px"
        label={false}
        trackColor="#C2DAFF"
        color="#0A6CFF"
      />
    );
  }, [displayQuestions]);

  const legacy = useMemo(() => {
    const survey = surveyConfig.getSurvey();
    const firstPage = survey.pages[0];
    const model: IQuestionSurveyModel = {
      ...survey,
      last_modified_by: '',
      index_group_id: '',
      metric: EMetricType.CSAT,
      smart_mode: true,
      pages: [
        {
          id: firstPage?.id ?? '',
          questions: (firstPage?.questions as IQuestionItem[]) ?? [],
        },
      ],
    };
    return model;
  }, [surveyConfig]);

  // 接口中获取的所有问题列表
  const metaQuestions = useMemo(() => {
    if (legacy.pages?.[0]?.questions.length === 0) {
      return [];
    }
    const questions = legacy.pages?.[0]?.questions ?? [];
    return convert2SurveyQuestions(questions);
  }, [legacy]);

  // 只有一道题的时候（单选题类型交互题目， 单道题的情况下直接点击后提交，不需要按钮），问卷文本居中显示
  const isOnlyOne = useMemo(() => {
    const questions = metaQuestions.filter((c) => c.is_deleted !== true);
    return questions.length === 1 && isRadioQuestion(questions?.[0]) && setting.isAlone;
  }, [metaQuestions, setting.isAlone]);

  return (
    <div className="tux-survey-view flex-columns" style={{ height: '100%' }}>
      <div className="flex-none">
        <div className="cus-progress-bar">
          <Row className="h-10px w-full " align="middle" style={{ background: '#F0F6FF' }}>
            <Col span={12} className="pl-24px pr-24px">
              {progressEle}
            </Col>
          </Row>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto">
        <div className="pl-24px pr-24px pt-16px">
          <TuxNativeBaseSurveyView
            ref={tuxNativeBaseSurveyViewRef}
            surveyConfig={surveyConfig}
            tuxEventListener={tuxEventListener}
            starOptionId={starOptionId}
            setting={setting}
            onDisplayQuestionsChange={(displayQuestions: SetStateAction<IQuestionResItem[]>) => {
              setDisplayQuestions(displayQuestions);
            }}
          />
        </div>
        {isOnlyOne ? null : (
          <div className="pl-24px pr-24px pb-48px">
            <SubmitButton
              onSubmit={async () => {
                await tuxNativeBaseSurveyViewRef?.current?.submit();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
