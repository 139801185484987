// 导入普通态和选择态的图片
import img1 from '@tux-meter-ui/assets/CSAT/1.png';
import img1Selected from '@tux-meter-ui/assets/CSAT/1_selected.png';
import img2 from '@tux-meter-ui/assets/CSAT/2.png';
import img2Selected from '@tux-meter-ui/assets/CSAT/2_selected.png';
import img3 from '@tux-meter-ui/assets/CSAT/3.png';
import img3Selected from '@tux-meter-ui/assets/CSAT/3_selected.png';
import img4 from '@tux-meter-ui/assets/CSAT/4.png';
import img4Selected from '@tux-meter-ui/assets/CSAT/4_selected.png';
import img5 from '@tux-meter-ui/assets/CSAT/5.png';
import img5Selected from '@tux-meter-ui/assets/CSAT/5_selected.png';
import imageNPS1 from '@tux-meter-ui/assets/NPS/1.svg';
import imageNPS10 from '@tux-meter-ui/assets/NPS/10.svg';
import imageStarFill from '@tux-meter-ui/assets/svgs/star-fill.svg';
import imageStar from '@tux-meter-ui/assets/svgs/star.svg';
import { Col, Row } from '@tux-meter-ui/components/Grid';
import {
  EQuestionItemType,
  IQuestionResStar,
  IQuestionStarChangeParams,
  IQuestionStarStyle,
} from '@tux-meter-ui/interface/survey';
import { getTitleByQuestionItem } from '@tux-meter-ui/utils/question-render-helper';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { EValidateErrorType, IQuestionItemRef, IQuestionItemValidateResult } from '../interface';
import './index.less';

interface ICsatImageInfo {
  normal: string;
  selected: string;
}

// 创建一个映射对象
const csatImages: Record<string, ICsatImageInfo> = {
  '1': { normal: img1, selected: img1Selected },
  '2': { normal: img2, selected: img2Selected },
  '3': { normal: img3, selected: img3Selected },
  '4': { normal: img4, selected: img4Selected },
  '5': { normal: img5, selected: img5Selected },
};

interface IQuestionStarProps {
  onChange: (value: IQuestionResStar, oldValue: IQuestionResStar, params: IQuestionStarChangeParams) => void;
  index?: number;
  value: IQuestionResStar;
}

/**
 * 问卷问题-量表题
 * @param {IQuestionStarProps} props
 * @returns
 */
export const QuestionStar = forwardRef<IQuestionItemRef, IQuestionStarProps>(({ onChange, index, value }, ref) => {
  const elementId = useMemo(() => `question-num-${index}`, [index]);
  const styleObj = useMemo(() => {
    const styleObj: IQuestionStarStyle = value.model.style
      ? JSON.parse(value.model.style.replace(/: "/g, ':"'))
      : { star_question_render_style: 'emoji' };
    return styleObj;
  }, [value.model]);

  const starType = useMemo(() => styleObj.star_question_render_style, [styleObj.star_question_render_style]);

  const optionEles = useMemo(() => {
    if (value.type !== EQuestionItemType.Star) {
      return [];
    }
    const options = value.model.options ?? [];
    return options.map((option) => (
      <Col className={`score-item ${value.value?.[0]?.id === option.id ? 'selected' : ''}`} flex={1} key={option.id}>
        <div
          onClick={() => {
            const res: IQuestionResStar = {
              ...value,
              value: [option],
            };
            onChange(res, value, { isDisplayChange: true, optionValue: option });
          }}
        >
          {option.id}
        </div>
      </Col>
    ));
  }, [value, onChange]);

  const ele = useMemo(() => {
    const options = value.model.options ?? [];
    if (starType === 'text') {
      return (
        <>
          <div className="mt-16px fs-16px fw-5">
            {value.model.description && <div className="mt-16px fs-12px c-666">{value.model.description}</div>}
          </div>
          <div className="flex-div score-img mt-16px w-full">
            <Row justify="space-between" className="w-full">
              <Col>
                <Row>
                  <Col>
                    <img src={imageNPS1} width="11" height="11" alt="" />
                  </Col>
                  <Col>
                    <span className="fs-s ml-4px">{options?.[0]?.text}</span>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row justify="end">
                  <Col>
                    <span className="fs-s" style={{ marginLeft: 'auto', marginRight: '4px' }}>
                      {options?.[options.length - 1]?.text}
                    </span>
                  </Col>
                  <Col>
                    <img src={imageNPS10} width="11" height="11" alt="" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="mt-8px">
            <Row gutter={8}>{optionEles}</Row>
          </div>
        </>
      );
    }

    const emojiOptionEles = options.map((option) => {
      const selected = value.value?.[0]?.id === option.id;
      const csatImage = csatImages[option.id];
      const imgPath = selected ? csatImage.selected : csatImage.normal;
      return (
        <Col flex={1} key={option.id}>
          <div
            onClick={() => {
              const res: IQuestionResStar = {
                ...value,
                value: [option],
              };
              onChange(res, value, { isDisplayChange: true, optionValue: option });
            }}
          >
            <Row justify="center">
              <Col>
                <img src={imgPath} width="30" height="30" alt="" />
              </Col>
            </Row>
            <Row justify="center" className="mt-4px">
              <Col>
                <span className="fs-12px c-assist-8">{option.text}</span>
              </Col>
            </Row>
          </div>
        </Col>
      );
    });

    const starOptionEles = options.map((option) => {
      const selected = value.value?.[0]?.id >= option.id;
      return (
        <Col flex={1} key={option.id}>
          <div className="star-item">
            <Row justify="center" className="w-100-p">
              <Col>
                <img
                  width="30"
                  height="30"
                  src={selected ? imageStarFill : imageStar}
                  onClick={() => {
                    const res: IQuestionResStar = {
                      ...value,
                      value: [option],
                    };
                    onChange(res, value, { isDisplayChange: true, optionValue: option });
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      );
    });

    return (
      <div className="img-div mt-18px">
        <Row>{starType === 'star' ? starOptionEles : emojiOptionEles}</Row>
      </div>
    );
  }, [value, starType, optionEles, onChange]);

  function validate(): IQuestionItemValidateResult {
    const { value: resValue, model } = value;
    const res: IQuestionItemValidateResult = {
      result: false,
      errors: [],
    };
    const title = index !== undefined ? `问题${index + 1}` : `问题：${model.title}`;
    if (resValue.length === 0) {
      res.errors.push({
        message: `${title}，尚未选择，请勾选后提交`,
        errorType: EValidateErrorType.QUESTION,
        element: document.getElementById(elementId),
      });
      return res;
    }
    res.result = true;
    return res;
  }

  useImperativeHandle(ref, () => ({
    validate,
  }));

  return (
    <div className="task-question-item client-question-star" id={elementId}>
      {index !== undefined && index !== 0 && <div className="divider"></div>}
      <div className="title">{getTitleByQuestionItem(value.model, index)}</div>
      {ele}
    </div>
  );
});
