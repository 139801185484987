import { IResResultT } from '@tux-meter-ui/h5/interface/common';
import { IDimensionModel } from '@tux-meter-ui/h5/interface/project';
import axios from 'axios';
import _ from 'lodash';

export const projectAxios = axios.create({
  baseURL: window.ENV_CONSTANT.CLINT_ENV === 'RELEASE' ? 'https://tux-gin.woa.com' : 'https://tux-web.testsite.woa.com',
  timeout: 1000000,
});

projectAxios.interceptors.request.use((config) => {
  const newConfig = _.cloneDeep(config);
  newConfig.withCredentials = true;
  newConfig.headers!['X-TUX-Protoc'] = 'trpc';
  return newConfig;
});

export const queryDimensions = async (app_id: string, id?: number) => {
  return (
    await projectAxios.post<IResResultT<IDimensionModel[]>>('/v1/trpc.xylophone.project.project/QueryDimensions', {
      app_id,
      id,
    })
  ).data;
};
