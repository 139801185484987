import { Col, Row } from '@tux-meter-ui/components/Grid';
import { TitleTag } from '@tux-meter-ui/components/TitleTag';
import {
  EQuestionItemSubType,
  EQuestionItemType,
  EQuestionMatrixDisplayStyle,
  EQuestionOptionStatus,
  IQuestionMatrixChangeParams,
  IQuestionMatrixResRow,
  IQuestionMatrixStyle,
  IQuestionOptionItem,
  IQuestionResItem,
  IQuestionResMatrix,
  IQuestionSubTitle,
  IQuestionSurveyModel,
} from '@tux-meter-ui/interface/survey';
import { isNumber } from '@tux-meter-ui/utils/number-ext';
import { getTitleByQuestionItem, getMaxTipsEle } from '@tux-meter-ui/utils/question-render-helper';
import _ from 'lodash';
import { forwardRef, Fragment, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import CusCheckbox from '../CusCheckbox';
import CusRadio from '../CusRadio';
import {
  EValidateErrorType,
  IQuestionItemRef,
  IQuestionItemValidateError,
  IQuestionItemValidateResult,
} from '../interface';
import './index.less';

interface IQuestionMatrixProps {
  disabled?: boolean;
  index?: number;
  onChange: (value: IQuestionResMatrix, oldValue: IQuestionResMatrix, params: IQuestionMatrixChangeParams) => void;
  value: IQuestionResMatrix;
  /**
   * 展示出来的题型并已填答结果（用于题型上下文关联校验等操作）
   */
  displayOfQuestionListResults: IQuestionResItem[];
  surveyModel: IQuestionSurveyModel;
  answerMaps: Record<string, IQuestionResItem>;
}

/**
 * QuestionMatrix
 * @param {IQuestionMatrixProps} props
 * @returns
 */
export const QuestionMatrix = forwardRef<IQuestionItemRef, IQuestionMatrixProps>(
  ({ index, onChange, value, displayOfQuestionListResults, surveyModel, answerMaps }, ref) => {
    const [errorMsg, setErrorMsg] = useState<string | undefined>();
    const elementId = useMemo(() => `question-num-${index}`, [index]);
    const refer = useMemo(() => value.model.refer, [value.model.refer]);

    const styleObj = useMemo(() => {
      const { style } = value.model;
      const styleObj: IQuestionMatrixStyle = style
        ? JSON.parse(style.replace(/: "/g, ':"'))
        : {
            display_style: 'matrix',
          };
      return styleObj;
    }, [value.model]);

    const questions = useMemo(() => {
      return surveyModel.pages?.[0]?.questions?.filter((c) => c.is_deleted !== true) ?? [];
    }, [surveyModel]);

    const referIndex = useMemo(() => {
      return questions.findIndex((c) => c.id === refer);
    }, [questions, refer]);

    const referAnswer = useMemo(() => {
      return answerMaps[refer ?? ''] as IQuestionResItem | undefined;
    }, [answerMaps, refer]);

    useEffect(() => {
      if (referAnswer && referAnswer.type === EQuestionItemType.Option) {
        const options = referAnswer.value ?? [];
        const rows = value.value ?? [];
        const newMatrixRows: IQuestionMatrixResRow[] = rows.map((row) => {
          const exist = options.findIndex((option) => option.text === row.text) > -1;
          if (!exist) {
            const options = row.options.map((option) => ({ ...option, status: EQuestionOptionStatus.UNCHOOSED }));
            return { ...row, options };
          }
          return { ...row };
        });
        const res = _.cloneDeep(value);
        res.value = newMatrixRows;
        if (JSON.stringify(res) !== JSON.stringify(value)) {
          onChange(res, value, { isDisplayChange: true });
        }
      }
    }, [referAnswer, value, onChange]);

    const referEmptyEle = useMemo(() => {
      if (referAnswer?.type === EQuestionItemType.Option) {
        const options = referAnswer.value ?? [];
        if (options.length === 0) {
          return (
            <div className="pl-18px pr-18px pt-20px pb-20px">
              此题选项来源于第{referIndex + 1}题中的选项，请先填写第{referIndex + 1}题
            </div>
          );
        }
      }
      return null;
    }, [referAnswer, referIndex]);

    const subTitles = useMemo(() => {
      const sub_titles = value.model?.sub_titles ?? [];
      if (referAnswer?.type === EQuestionItemType.Option) {
        const selectedOptions = referAnswer.value;
        const options = referAnswer.model.options.reduce((res: IQuestionOptionItem[], item) => {
          const index = selectedOptions.findIndex((k) => k.text === item.text);
          if (index > -1) {
            return [...res, item];
          }
          return res;
        }, []);
        return options.reduce((res: IQuestionSubTitle[], option) => {
          const model = sub_titles.find((k) => k.text === option.text);
          if (model) {
            return [...res, model];
          }
          return res;
        }, []);
      }
      return sub_titles;
    }, [referAnswer, value.model?.sub_titles]);

    const subTypeTitleEle = useMemo(() => {
      if (value.model.sub_type === EQuestionItemSubType.MATRIX_RADIO) {
        return <span className="multiple-tag fs-14px">单选</span>;
      }
      return <span className="multiple-tag fs-14px">多选</span>;
    }, [value.model.sub_type]);

    const ele = useMemo(() => {
      const item = value;
      if (item.type !== EQuestionItemType.Matrix) {
        return null;
      }
      const { options, sub_type } = item.model;
      const { display_style, max, line_items } = styleObj;

      if (display_style === EQuestionMatrixDisplayStyle.MATRIX) {
        const optionTitleEles = options.map((option) => {
          const { text } = option;
          return (
            <div className="grid-item style-matrix" key={text}>
              <Row justify="center" align="middle" className="option-title">
                <Col>{text}</Col>
              </Row>
            </div>
          );
        });

        const rowEles = subTitles.reduce((res: JSX.Element[], sub_title) => {
          const { text } = sub_title;
          const subTitleEle = (
            <div className="grid-item full-row" key={text}>
              <Row align="middle" gutter={8}>
                <Col>
                  <span id={`question-matrix-sub-title-${sub_title.id}`}>{text}</span>
                </Col>
              </Row>
            </div>
          );

          const optionEles = options.map((option) => {
            const { id } = option;
            const rowIndex = item.value.findIndex((k) => k.id === sub_title.id);
            const colIndex = item.value[rowIndex]?.options?.findIndex((opt) => opt.id === id) ?? -1;
            const currResOption = item.value[rowIndex]?.options?.[colIndex];
            const selected = currResOption?.status === EQuestionOptionStatus.CHOOSED;

            if (sub_type === EQuestionItemSubType.MATRIX_RADIO) {
              return (
                <div className="grid-item" key={id}>
                  <Row align="middle" justify="center">
                    <Col>
                      <CusRadio
                        checked={selected}
                        onChange={() => {
                          const res = _.cloneDeep(item);
                          const newMatrixRows = res.value;
                          if (newMatrixRows?.[rowIndex]?.options?.[colIndex]) {
                            if (sub_type === EQuestionItemSubType.MATRIX_RADIO) {
                              newMatrixRows[rowIndex].options.forEach((_colItem, colIndex) => {
                                if (
                                  newMatrixRows[rowIndex].options[colIndex].status !== EQuestionOptionStatus.UNSHOWED
                                ) {
                                  newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.UNCHOOSED;
                                }
                              });
                            }
                            const newSelected = !selected;
                            newMatrixRows[rowIndex].options[colIndex].status = newSelected
                              ? EQuestionOptionStatus.CHOOSED
                              : EQuestionOptionStatus.UNCHOOSED;
                            onChange(res, item, { isDisplayChange: true });
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              );
            }

            return (
              <div className="grid-item" key={id}>
                <Row align="middle" justify="center">
                  <Col>
                    <CusCheckbox
                      checked={selected}
                      onClick={() => {
                        const res = _.cloneDeep(item);
                        const newMatrixRows = res.value;
                        if (newMatrixRows?.[rowIndex]?.options?.[colIndex]) {
                          if (!selected && max !== undefined && isNumber(max)) {
                            const options = newMatrixRows[rowIndex].options.filter(
                              (option) => option.status === EQuestionOptionStatus.CHOOSED,
                            );
                            if (Number(max) <= options.length) {
                              setErrorMsg(`每行最多选${max}项`);
                              onChange(item, item, {
                                isDisplayChange: true,
                                validateResult: {
                                  result: false,
                                  errors: [
                                    {
                                      message: `每行最多选${max}项`,
                                      errorType: EValidateErrorType.TITLE,
                                    },
                                  ],
                                },
                              });
                              return;
                            }
                          }
                          if (selected) {
                            newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.UNCHOOSED;
                          } else {
                            if (res.model.options[colIndex].exclusive) {
                              newMatrixRows[rowIndex].options.forEach((item) => {
                                item.status = EQuestionOptionStatus.UNCHOOSED;
                              });
                              newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.CHOOSED;
                            } else {
                              newMatrixRows[rowIndex].options.forEach((item, i) => {
                                if (res.model.options[i].exclusive) {
                                  item.status = EQuestionOptionStatus.UNCHOOSED;
                                }
                              });
                              newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.CHOOSED;
                            }
                          }
                          onChange(res, item, { isDisplayChange: true });
                        }
                      }}
                    />
                  </Col>
                </Row>
              </div>
            );
          });

          const className = `grid-container w-full grid-fr-${options.length}`;
          const itemEle = (
            <div className={className} key={sub_title.id}>
              {subTitleEle}
              {optionEles.map((optionEle, index) => (
                <Fragment key={index}>{optionEle}</Fragment>
              ))}
            </div>
          );
          return [...res, itemEle];
        }, []);

        const className = `grid-container w-full grid-fr-${options.length}`;
        return (
          <div className="task-question-item task-question-item-matrix" id={elementId}>
            {index !== 0 ? <div className="divider"></div> : null}
            <div className="title">
              <Row gutter={8}>
                <Col className="fs-16px fw-5">{getTitleByQuestionItem(item.model, index)}</Col>
                <Col>{subTypeTitleEle}</Col>
                {errorMsg && (
                  <Col>
                    <TitleTag
                      duration={2000}
                      onClose={() => {
                        setErrorMsg(undefined);
                      }}
                    >
                      {errorMsg}
                    </TitleTag>
                  </Col>
                )}
              </Row>
            </div>
            {sub_type === EQuestionItemSubType.MATRIX_RADIO && !item.model.required
              ? null
              : getMaxTipsEle(max, item.model.required, '每行')}
            {referEmptyEle ? (
              referEmptyEle
            ) : (
              <div className={className}>
                <div className="full-row">
                  <div className={className}>{optionTitleEles}</div>
                </div>
                <div className="full-row">
                  <div className="w-full">{rowEles}</div>
                </div>
              </div>
            )}
          </div>
        );
      }

      const rowEles = subTitles.reduce((res: JSX.Element[], sub_title) => {
        const { text } = sub_title;
        const subTitleEle = (
          <Row align="middle" gutter={8} className="mt-12px" key={text}>
            <Col>
              <span id={`question-matrix-sub-title-${sub_title.id}`}>{text}</span>
            </Col>
          </Row>
        );

        const optionEles = options.map((option) => {
          const { id, text } = option;
          const rowIndex = item.value.findIndex((k) => k.id === sub_title.id);
          const colIndex = item.value[rowIndex]?.options?.findIndex((opt) => opt.id === id) ?? -1;
          const currResOption = item.value[rowIndex]?.options?.[colIndex];
          const selected = currResOption?.status === EQuestionOptionStatus.CHOOSED;
          const columnCount = isNumber(line_items) ? Number(line_items) : 3;

          return (
            <Col avg={columnCount} key={id}>
              <div
                onClick={() => {
                  const res = _.cloneDeep(item);
                  const newMatrixRows = res.value;
                  if (newMatrixRows?.[rowIndex]?.options?.[colIndex]) {
                    if (
                      sub_type === EQuestionItemSubType.MATRIX_CHECKBOX &&
                      !selected &&
                      max !== undefined &&
                      isNumber(max)
                    ) {
                      const options = newMatrixRows[rowIndex].options.filter(
                        (option) => option.status === EQuestionOptionStatus.CHOOSED,
                      );
                      if (Number(max) <= options.length) {
                        setErrorMsg(`每行最多选${max}项`);
                        onChange(item, item, {
                          isDisplayChange: true,
                          validateResult: {
                            result: false,
                            errors: [
                              {
                                message: `每行最多选${max}项`,
                                errorType: EValidateErrorType.TITLE,
                              },
                            ],
                          },
                        });
                        return;
                      }
                    }
                    if (sub_type === EQuestionItemSubType.MATRIX_RADIO) {
                      newMatrixRows[rowIndex].options.forEach((_colItem, colIndex) => {
                        if (newMatrixRows[rowIndex].options[colIndex].status !== EQuestionOptionStatus.UNSHOWED) {
                          newMatrixRows[rowIndex].options[colIndex].status = EQuestionOptionStatus.UNCHOOSED;
                        }
                      });
                    }
                    const newSelected = !selected;
                    newMatrixRows[rowIndex].options[colIndex].status = newSelected
                      ? EQuestionOptionStatus.CHOOSED
                      : EQuestionOptionStatus.UNCHOOSED;
                    onChange(res, item, { isDisplayChange: true });
                  }
                }}
              >
                {sub_type === EQuestionItemSubType.MATRIX_RADIO ? (
                  <CusRadio checked={selected} />
                ) : (
                  <CusCheckbox checked={selected} />
                )}
                <span className="ml-8px">{text}</span>
              </div>
            </Col>
          );
        });

        const itemEle = (
          <div key={sub_title.id}>
            {subTitleEle}
            <Row gutter={8}>{optionEles}</Row>
          </div>
        );
        return [...res, itemEle];
      }, []);

      return (
        <div className="task-question-item task-question-item-matrix" id={elementId}>
          {index !== 0 ? <div className="divider"></div> : null}
          <div className="title">
            <Row gutter={8}>
              <Col className="fs-16px fw-5">{getTitleByQuestionItem(item.model, index)}</Col>
              <Col>{subTypeTitleEle}</Col>
              {errorMsg && (
                <Col>
                  <TitleTag
                    duration={2000}
                    onClose={() => {
                      setErrorMsg(undefined);
                    }}
                  >
                    {errorMsg}
                  </TitleTag>
                </Col>
              )}
            </Row>
          </div>
          {sub_type === EQuestionItemSubType.MATRIX_RADIO && !item.model.required
            ? null
            : getMaxTipsEle(max, item.model.required, '每行')}
          {referEmptyEle ? referEmptyEle : rowEles}
        </div>
      );
    }, [value, elementId, index, subTitles, subTypeTitleEle, styleObj, errorMsg, referEmptyEle, onChange]);

    function validate(): IQuestionItemValidateResult {
      const { value: resValue, model } = value;
      const res: IQuestionItemValidateResult = {
        result: false,
        errors: [],
      };
      let subTitles = model.sub_titles;
      const referAnswer = displayOfQuestionListResults.find((c) => c.model.id === model.refer);
      if (referAnswer?.model?.sub_type === EQuestionItemSubType.CHECKBOX) {
        const referAnswerOptions = referAnswer.value as IQuestionOptionItem[];
        subTitles = referAnswerOptions.map((option) => ({ ...option, is_deleted: !!option.is_deleted }));
      }
      const errors = subTitles.reduce((res: IQuestionItemValidateError[], sub_title) => {
        const rowModel = resValue.find((k) => k.text === sub_title.text);
        if (rowModel) {
          const chooseArr = rowModel.options.filter((c) => c.status === EQuestionOptionStatus.CHOOSED);
          if (chooseArr.length === 0) {
            const errorModel: IQuestionItemValidateError = {
              message: `${sub_title.text}尚未选择，请勾选后提交`,
              errorType: EValidateErrorType.TIP,
              element: document.getElementById(`question-matrix-sub-title-${sub_title.id}`),
            };
            return [...res, errorModel];
          }
        }
        return res;
      }, []);
      if (errors.length > 0) {
        return { result: false, errors };
      }
      res.result = true;
      return res;
    }

    useImperativeHandle(ref, () => ({
      validate,
    }));

    return ele;
  },
);
