/**
 * TuxUIConstants 类包含与 Tux UI 相关的常量和方法。
 */
export class TuxUIConstants {
  /** 提示的半径 */
  public static readonly TUX_VIEW_TIPS_RADIUS: number = 8;

  /** 弹窗（第一道题） + 全屏网页 */
  public static readonly TRIGGER_STYLE_DIALOG_FULLSCREEN: number = 0;
  /** Tips 组件 + 全屏网页 */
  public static readonly TRIGGER_STYLE_TIPS: number = 1;
  /** Hippy */
  public static readonly TRIGGER_STYLE_HIPPY: number = 2;
  /** 前端(hippy/h5)嵌入式（第一道题） + 半浮层 */
  public static readonly TRIGGER_STYLE_EMBEDDED_HIPPY_FLOAT: number = 3;
  /** native嵌入式（第一道题）+ 半浮层 */
  public static readonly TRIGGER_STYLE_EMBEDDED_FLOAT: number = 4;
  /** 弹窗（第一道题）+ 半浮层 */
  public static readonly TRIGGER_STYLE_DIALOG_FLOAT: number = 5;
  /** native嵌入式卡片展开 */
  public static readonly TRIGGER_STYLE_NATIVE: number = 6;

  /** POP样式信息 - 标题 */
  public static readonly POP_TITLE: string = 'title';
  /** POP样式信息 - 副标题 */
  public static readonly POP_SUB_TITLE: string = 'subtitle';
  /** POP样式信息 - 图标 URL */
  public static readonly POP_ICON_URL: string = 'iconUrl';
  /** POP样式信息 - 按钮文本 */
  public static readonly POP_BTN_TEXT: string = 'btnText';
  /** 敏感答案 URL */
  public static readonly SENSITIVE_ANSWER_URL: string =
    'https://tux.qq.com/cos/tux_ui/static/img/const/SENSITIVE_ANSWER_URL.png';
  /** 敏感信息 URL */
  public static readonly SENSITIVE_INFO_URL: string =
    'https://privacy.qq.com/document/preview/2491347092a64d7fa00cbc2bf68fbbbb';

  /**
   * 判断给定的样式类型是否为弹窗触发器。
   * @param styleType 样式类型
   * @returns 如果是弹窗触发器，则返回 true；否则返回 false。
   */
  public static isDialogTrigger(styleType: number): boolean {
    return (
      styleType === TuxUIConstants.TRIGGER_STYLE_DIALOG_FULLSCREEN ||
      styleType === TuxUIConstants.TRIGGER_STYLE_TIPS ||
      styleType === TuxUIConstants.TRIGGER_STYLE_DIALOG_FLOAT
    );
  }

  /**
   * 判断给定的样式类型是否为嵌入式触发器。
   * @param styleType 样式类型
   * @returns 如果是嵌入式触发器，则返回 true；否则返回 false。
   */
  public static isEmbeddedTrigger(styleType: number): boolean {
    return (
      styleType === TuxUIConstants.TRIGGER_STYLE_EMBEDDED_HIPPY_FLOAT ||
      styleType === TuxUIConstants.TRIGGER_STYLE_EMBEDDED_FLOAT
    );
  }

  /**
   * 判断给定的样式类型是否为原生触发器。
   * @param styleType 样式类型
   * @returns 如果是原生触发器，则返回 true；否则返回 false。
   */
  public static isNativeTrigger(styleType: number): boolean {
    return styleType === TuxUIConstants.TRIGGER_STYLE_NATIVE;
  }

  /**
   * 判断给定的样式类型是否为 Tips 组件类型。
   * @param styleType 样式类型
   * @returns 如果是 Tips 组件类型，则返回 true；否则返回 false。
   */
  public static isTriggerStyleTips(styleType: number): boolean {
    return styleType === TuxUIConstants.TRIGGER_STYLE_TIPS;
  }
}
