import {
  ESurveyCallbackFn,
  getPersonalizedLegacy,
  IGetPersonalizedLegacyArgs,
  ISubmitSurveyArgs,
  submitSurvey,
} from '@tux-meter-ui/h5/api/survey';
import ImageError from '@tux-meter-ui/h5/assets/error.png';
import CusLoading from '@tux-meter-ui/h5/components/CusLoading';
import { Col, Row } from '@tux-meter-ui/h5/components/Grid';
import { isAndroid } from '@tux-meter-ui/h5/const';
import { EQuestionItemType, IQuestionResItem, IQuestionSurveyModel } from '@tux-meter-ui/h5/interface/survey';
import { beacon } from '@tux-meter-ui/h5/utils/beacon';
import { HtmlHelper } from '@tux-meter-ui/h5/utils/html-helper';
import { useRoute, useRouter } from '@tux-meter-ui/h5/utils/router-helper';
import { getTuxSurveyConfigByLegacy, onH5SubmitAnswer } from '@tux-meter-ui/h5/utils/survey-helper';
import { ISubmitValidateResult, TuxMeterUI } from '@tux-meter-ui/index';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Icon } from 'tdesign-icons-react';
import { MessagePlugin, Progress } from 'tdesign-react';
import './index.less';

/**
 * Csat问卷
 * @returns
 */
export default function Csat() {
  const route = useRoute();
  const router = useRouter();
  const halfDialogArr = useMemo(() => ['3', '4', '5'], []);
  const [haveSurvey, setHaveSurvey] = useState(false);
  const [q36Init, setQ36Init] = useState<boolean>(false);
  const [q36, setQ36] = useState<string | undefined>();
  const [isFocus, setIsFocus] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [legacy, setLegacy] = useState<IQuestionSurveyModel | undefined>();
  // 已选择的题目（map）
  const surveyPanelRef = useRef<HTMLDivElement>(null);
  const [surveyEle, setSurveyEle] = useState<HTMLElement | undefined>();
  // 使用 useRef 存储 getTuxNativeBaseSurveyView 返回的 submit 函数
  const tuxMeterSubmitFnRef = useRef<() => Promise<ISubmitValidateResult | undefined>>();
  // 展示的结果
  const [displayQuestions, setDisplayQuestions] = useState<IQuestionResItem[]>([]);
  // 构建 TuxMeterUI.getTuxNativeBaseSurveyView 函数需要的参数
  const tuxSurveyConfig = useMemo(() => {
    if (legacy) {
      return getTuxSurveyConfigByLegacy(legacy);
    }
    return undefined;
  }, [legacy]);
  useEffect(() => {
    if (tuxSurveyConfig) {
      const { surveyEle, submit } = TuxMeterUI.getTuxNativeBaseSurveyView({
        surveyConfig: tuxSurveyConfig,
        setting: {},
        starOptionId: '',
        tuxEventListener: {
          onDisplayQuestionsChange: (displayQuestions) => {
            setDisplayQuestions(displayQuestions);
          },
        },
      });
      // 获取嵌入问卷元素
      setSurveyEle(surveyEle);
      tuxMeterSubmitFnRef.current = submit;
    }
  }, [tuxSurveyConfig]);
  useEffect(() => {
    if (surveyPanelRef.current && surveyEle) {
      surveyPanelRef.current.replaceChildren(surveyEle);
    }
  }, [surveyPanelRef, surveyEle]);
  const zone_id = useMemo(() => {
    // zone_id作为区服id，后续存在改名的情况，它作为子id。
    // 用于and条件触发问卷，比如uid&sub_uid匹配的情况下弹出问卷
    const sub_uid = route.query.sub_uid as string | undefined;
    return sub_uid ?? (route.query.zone_id as string) ?? '';
  }, [route.query.sub_uid, route.query.zone_id]);
  const channel = useMemo(() => {
    return (route.query.channel as string) ?? '';
  }, [route.query.channel]);
  const ext_info = useMemo(() => {
    // 获取url参数中所有tux_开头的参数，存放至对象中
    const extInfoMap = Object.keys(route.query).reduce((res: Record<string, string>, key) => {
      if (/^tux_/.test(key)) {
        const argKey = key.replace(/^tux_/, '');
        const argVal = route.query[key];
        const result: Record<string, string> = { ...res };
        if (_.isString(argVal)) {
          result[argKey] = argVal;
        }
        return result;
      }
      return res;
    }, {});
    // 对象个数大于0的情况，需要将这个对象转换为后端需要的utf8 json字符串
    if (Object.keys(extInfoMap).length > 0) {
      // 1、先将 obj 对象转换json 字符串
      // 2、再使用encodeURIComponent转义中文字符
      // 3、最后再调用btoa转换base64
      /*
      const base64Str = btoa(encodeURIComponent(JSON.stringify(extInfo)));
      */
      // 1、先将obj转化为json字符串
      // 2、将字符串转成utf-8编码字节数组
      // 3、最后进行base64编码
      const str = JSON.stringify(extInfoMap);
      const utf8Bytes = new TextEncoder().encode(str);
      const base64Str = btoa(String.fromCharCode(...utf8Bytes));
      return base64Str;
    }
    const ext_info = route.query.ext_info as string | undefined;
    return ext_info;
  }, [route.query]);
  const cbFns = useMemo(() => {
    const fns = (route.query.cb_fns as string) ?? '';
    return fns.split(',');
  }, [route.query.cb_fns]);
  const survey_type = useMemo(() => {
    // CSAT或NPS 转换为小写
    return route.params.survey_type?.toLocaleLowerCase();
  }, [route.params.survey_type]);
  const title = useMemo(() => {
    const title = legacy?.title ?? '';
    if (_.isString(title) && title.length > 0) {
      return title;
    }
    return '用户调研';
  }, [legacy?.title]);
  useEffect(() => {
    HtmlHelper.setTitle(title);
  }, [title]);
  const uuid = useMemo(() => {
    const task_id = route.query.task_id as string | undefined;
    const uid = route.query.uid as string | undefined;
    // 如果有task_id和uid的情况，则使用这两个参数拼接为uuid
    // 单一uuid的queryArg用户难以理解为什么要拼接成这样，例如: 001330-2f64f5f5256149b66221f29910001a215328
    // 所以兼容url参数可以为: survey_id=111&task_id=1330&uid=2f64f5f5256149b66221f29910001a215328
    if (task_id && uid) {
      // 补充id为6位
      const left = _.padStart(task_id, 6, '0');
      // 拼接uuid
      // 文档参考https://iwiki.woa.com/pages/viewpage.action?pageId=1689393596
      return `${left}-${uid}`;
    }
    const taskinfo_id = route.query.taskinfo_id as string | undefined;
    // 如果有传递task信息的id过来，则使用uuid拼接
    if (taskinfo_id && q36) {
      // 补充id为6位
      const left = _.padStart(taskinfo_id, 6, '0');
      // 拼接uuid
      // 文档参考https://iwiki.woa.com/pages/viewpage.action?pageId=1689393596
      return `${left}-${q36}`;
    }
    return (route.query.uuid as string) ?? '';
  }, [route.query, q36]);
  // userId取之于uuid的第一个 - 后的所有值
  // uuid可能为 001234-abccxaxa 或者 01234-1j414-2121n
  // 上述实例就需要userId为 abccxaxa 或 1j414-2121n
  const userId = useMemo(() => {
    const [taskIdStr] = uuid.split('-');
    return uuid.replace(`${taskIdStr}-`, '');
  }, [uuid]);
  const personalizedLegacyArgs = useMemo(() => {
    const { app_id, survey_id } = route.query as Record<string, string | undefined>;
    if (survey_id && q36Init) {
      return {
        zone_id: zone_id,
        survey_id,
        user_id: userId ?? '',
        business: app_id ?? 'qb',
        is_white: route.query.is_white === '1',
        is_permanent: route.query.is_permanent === '1',
      };
    }
    return undefined;
  }, [route.query, q36Init, zone_id, userId]);
  const position = useMemo(() => route.query.position, [route.query.position]);
  const surveyID = useMemo(() => (route.query.survey_id as string) ?? '', [route.query.survey_id]);
  const isHalfDialog = useMemo(
    () => halfDialogArr.indexOf(route.query.component_type as string) > -1,
    [route.query.component_type, halfDialogArr],
  );
  const fetchFn = useCallback(
    async (personalizedLegacyArgs: IGetPersonalizedLegacyArgs) => {
      if (personalizedLegacyArgs) {
        try {
          setLoading(true);
          const startTime = performance.now();
          const res = await getPersonalizedLegacy(personalizedLegacyArgs, route.query.app_id as string);
          const endTime = performance.now();
          const elapsedTime = endTime - startTime;
          // 上报请求耗时
          beacon.onDirectUserAction('tux_h5_query_elapsed_time', {
            tux_zone_id: zone_id,
            env: window.ENV_CONSTANT.CLINT_ENV,
            iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
            survey_type: survey_type,
            server_type: 'H5',
            elapsed_time: elapsedTime,
            ...personalizedLegacyArgs,
            query_res: JSON.stringify(res),
          });
          if (res?.pages.length && res?.pages.length > 0) {
            const pages = res?.pages ?? [];
            const questions = pages?.[0]?.questions ?? [];
            const newQuestions = questions.map((question) => {
              // 旧的数据没有required时，默认必填
              if (
                (question.type === EQuestionItemType.Option || question.type === EQuestionItemType.Star) &&
                question.required === undefined
              ) {
                question.required = true;
              }
              return question;
            });
            res.pages[0].questions = newQuestions;
          }
          setLegacy(res);
          setLoading(false);
          setHaveSurvey(!!res);
        } catch (err) {
          console.log('err:', err);
          setLegacy(undefined);
          setLoading(false);
        }
      }
    },
    [route.query.app_id, survey_type, zone_id],
  );
  useEffect(() => {
    if (personalizedLegacyArgs) {
      fetchFn(personalizedLegacyArgs);
    }
  }, [personalizedLegacyArgs, fetchFn]);
  const progressEle = useMemo(() => {
    // 必填的总数
    const total = displayQuestions.filter((c) => c.model.required).length;
    const count = displayQuestions.reduce((res: number, item) => {
      if (item.model.required) {
        const { type, value } = item;
        if (type === EQuestionItemType.Star || type === EQuestionItemType.Option) {
          if (value.length > 0) {
            return res + 1;
          }
        }
        if (type === EQuestionItemType.Text) {
          if (value.length !== 0 && value !== undefined) {
            return res + 1;
          }
        }
      }
      return res;
    }, 0);
    const percentage = (count / total) * 100;
    return (
      <Progress
        theme="plump"
        percentage={percentage}
        strokeWidth="3px"
        label={null}
        trackColor="#C2DAFF"
        color="#0A6CFF"
      />
    );
  }, [displayQuestions]);
  const iframeTopEmptyEle = useMemo(() => {
    if (window === window.top) {
      return null;
    }
    // 当前页面被嵌套在一个 iframe 中
    return [<div className="iframe-empty"></div>, <div className="iframe-empty-placeholder"></div>];
  }, []);
  const reportErrorEvent = useCallback(
    (msg: string, body?: string) => {
      beacon.onDirectUserAction('tux_h5_submit_error', {
        tux_zone_id: zone_id,
        router: window.location.href,
        user_id: 'runhuasu',
        env: window.ENV_CONSTANT.CLINT_ENV,
        iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
        tux_uid: userId,
        tux_uuid: uuid || 'test',
        survey_id: surveyID,
        survey_type: survey_type,
        server_type: 'H5',
        msg,
        app_id: route.query.app_id ?? 'qb',
        body: body ?? '',
      });
    },
    [route.query.app_id, surveyID, survey_type, userId, uuid, zone_id],
  );
  const cancelBtnEle = useMemo(() => {
    const index = cbFns.findIndex((c) => c === ESurveyCallbackFn.CANCEL);
    if (index === -1) {
      return null;
    }
    const isFrameClass = window !== window.top ? 'is-iframe' : '';
    return (
      <div className={`close ${isFrameClass}`}>
        <Icon
          name="close"
          className="fs-26px"
          onClick={() => {
            if (window.parent.postMessage) {
              window.parent.postMessage(ESurveyCallbackFn.CANCEL, '*');
              console.log(`触发 ${ESurveyCallbackFn.CANCEL}`);
              return;
            }
            reportErrorEvent(
              `触发postMessage ${ESurveyCallbackFn.CANCEL} 失败，该环境不存在window.parent.postMessage函数`,
            );
          }}
        />
      </div>
    );
  }, [cbFns, reportErrorEvent]);
  const submit = async () => {
    const validateResult = await tuxMeterSubmitFnRef.current?.();
    console.log('validateResult:', validateResult);
    if (validateResult === undefined) {
      MessagePlugin.warning('验证失败');
      reportErrorEvent('tuxMeterSubmitFn 返回的 validateResult 为 undefined');
    }
    const { validate, payload } = validateResult ?? {};
    // 验证问题填答失败的情况
    if (!validate) {
      return false;
    }
    if (surveyID && uuid && validate && payload) {
      const query: ISubmitSurveyArgs = {
        survey_id: surveyID,
        // h5 platform必须为空字符串，其他场景不可为空
        platform: '',
        payload: {
          zone_id: zone_id,
          openid: uuid as string,
          channel,
          ...payload,
        },
        is_white: route.query.is_white === '1',
        app_id: route.query.app_id === undefined ? '' : (route.query.app_id as string),
        ext_info,
      };
      const params: any = {
        zone_id,
        router: window.location.href,
        user_id: 'runhuasu',
        env: window.ENV_CONSTANT.CLINT_ENV,
        tux_uid: userId,
        tux_uuid: uuid || 'test',
        survey_id: surveyID,
        survey_type,
        server_type: 'H5',
        app_id: route.query.app_id ?? 'qb',
        is_white: route.query.is_white === '1'.toString(),
      };
      if (position !== '' && position !== undefined) {
        params.position = position;
        params.tux_position = position;
      }
      beacon.onDirectUserAction('tux_survey_submit', params);
      try {
        setSubmitLoading(true);
        const res = await submitSurvey(query, route.query.app_id as string);
        if (res.data.code === 0) {
          beacon.onDirectUserAction('tux_h5_submit_success', {
            tux_zone_id: zone_id,
            router: window.location.href,
            user_id: 'runhuasu',
            env: window.ENV_CONSTANT.CLINT_ENV,
            iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
            tux_uid: userId,
            tux_uuid: uuid || 'test',
            survey_id: surveyID,
            survey_type,
            server_type: 'H5',
            app_id: route.query.app_id ?? 'qb',
            body: JSON.stringify(query),
          });
        } else {
          reportErrorEvent(res.data.msg, JSON.stringify(query));
        }
      } catch (err) {
        reportErrorEvent(JSON.stringify(err), JSON.stringify(query));
      }
    } else {
      reportErrorEvent(`缺少 ${surveyID ? '' : 'survey_id'} ${uuid ? '' : 'uuid'} 参数`);
      MessagePlugin.warning(`缺少 ${surveyID ? '' : 'survey_id'} ${uuid ? '' : 'uuid'} 参数`);
    }
    window.browser?.app?.toast?.('提交成功，感谢你的反馈', 1);
    if (!isHalfDialog) {
      window.browser?.app?.historyBack?.();
    }
    onH5SubmitAnswer();
    router.replace({
      name: 'SurveySuccessView',
    });
    setTimeout(() => {
      setSubmitLoading(false);
    }, 1000);
    const cbSuccessIndex = cbFns.findIndex((c) => c === ESurveyCallbackFn.SUCCESS);
    if (cbSuccessIndex > -1 && window.parent.postMessage) {
      window.parent.postMessage(ESurveyCallbackFn.SUCCESS, '*');
      console.log(`触发 ${ESurveyCallbackFn.CANCEL}`);
      return;
    }
  };
  useEffect(() => {
    if (isAndroid) {
      // 获取原窗口的高度
      const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
      window.onresize = () => {
        // 键盘弹起与隐藏都会引起窗口的高度发生变化
        const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
        if (resizeHeight - 0 < originalHeight - 0) {
          // 当软键盘弹起，在此处操作
          // _self.show_bo = false;
        } else {
          // 当软键盘收起，在此处操作
          setIsFocus(false);
        }
      };
    }
  }, []);
  useEffect(() => {
    if (window?.browser?.app?.getBrowserParam) {
      window?.browser?.app?.getBrowserParam?.(
        (pararms) => {
          const { qimei36 } = pararms;
          setQ36(qimei36);
          setQ36Init(true);
        },
        (error) => {
          setQ36Init(true);
          beacon.onDirectUserAction('tux_h5_error_log', {
            tux_zone_id: zone_id,
            env: window.ENV_CONSTANT.CLINT_ENV,
            iplatform: window.ENV_CONSTANT.PLATFORM_NAME,
            tux_uid: userId,
            tux_uuid: uuid || 'test',
            survey_id: surveyID,
            survey_type,
            server_type: 'H5',
            msg: `从 getBrowserParam 获取q36失败：${error}`,
            app_id: route.query.app_id ?? 'qb',
          });
        },
      );
      return;
    }
    setQ36(route.query.q36 as string);
    setQ36Init(true);
  }, [route.query.app_id, route.query.q36, surveyID, survey_type, userId, uuid, zone_id]);
  const isFrameClass = window !== window.top ? 'is-iframe' : '';
  console.log('router:', router.flatRoutes);
  if (loading) {
    return (
      <div
        style={{
          height: 'calc(100vh)',
          width: 'calc(100vw)',
        }}
      >
        <Row className="w-100-p h-100-p" justify="center" align="middle" gutter={8}>
          <Col>
            <CusLoading />
          </Col>
          <Col className="c-pri-6 fs-16px fw-5">加载问卷内容中...</Col>
        </Row>
      </div>
    );
  }
  if (!haveSurvey) {
    return (
      <div className="container">
        {cancelBtnEle}
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <img src={ImageError} width="196" height="228" style={{ marginTop: '194px' }} />
          <div
            style={{
              color: '#8f8f8f',
              fontSize: '16px',
              lineHeight: '20px',
            }}
          >
            网络出错，请稍后重试
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="csat-survey-panel">
      {iframeTopEmptyEle}
      {cancelBtnEle}
      <div className={`cus-progress-bar ${isFrameClass}`}>
        <Row className="h-10px w-100-p " align="middle" style={{ background: '#F0F6FF' }}>
          <Col span={12} className="pl-24px pr-24px">
            {progressEle}
          </Col>
        </Row>
      </div>
      <div className="pl-24px pr-24px pt-16px" ref={surveyPanelRef}></div>
      <div className="pl-24px pr-24px pt-16px pb-48px">
        <div
          style={
            isFocus
              ? {
                  position: 'static',
                  margin: '16px auto',
                  transform: 'none',
                }
              : {}
          }
          className="submit"
          onClick={() => {
            submit();
          }}
        >
          <Row align="middle" justify="center" gutter={16} className="h-100-p c-fff">
            {submitLoading ? (
              [
                <Col>
                  <Icon name="loading" />
                </Col>,
                <Col>提交中</Col>,
              ]
            ) : (
              <Col>提交</Col>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}
