import { queryDimensions } from '@tux-meter-ui/h5/api/project-request';
import { handlePersonalRecommendQuestion, initializeQuestions } from '@tux-meter-ui/h5/api/survey';
import { IFileInfo, getFileById } from '@tux-meter-ui/h5/bus/use-magic-conch-file';
import CusLoading from '@tux-meter-ui/h5/components/CusLoading';
import { ErrorBoundary } from '@tux-meter-ui/h5/components/ErrorBoundary';
import { Col, Row } from '@tux-meter-ui/h5/components/Grid';
import QuestionPreviewer from '@tux-meter-ui/h5/components/QuestionPreviewer';
import { IDimensionModel } from '@tux-meter-ui/h5/interface/project';
import { EQuestionItemType, IQuestionItem } from '@tux-meter-ui/h5/interface/survey';
import { useRoute } from '@tux-meter-ui/h5/utils/router-helper';
import { convert2SurveyQuestions } from '@tux-meter-ui/h5/utils/survey-helper';
import _ from 'lodash';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { MessagePlugin } from 'tdesign-react';
import './index.less';

/**
 * 问卷预览页面
 */
function SurveyPreview() {
  const route = useRoute();
  const [fileInfo, setFileInfo] = useState<IFileInfo | undefined>();
  const [dimensions, steDimensions] = useState<IDimensionModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [q36, setQ36] = useState<string | undefined>();

  const fileInfoId = useMemo(() => {
    return route.query.fileInfoId as string;
  }, [route.query.fileInfoId]);

  const fetchFn = useCallback(async () => {
    if (fileInfoId) {
      setLoading(true);
      const model: IFileInfo | undefined = await getFileById(fileInfoId);
      setFileInfo(model);
      const { app_id }: { app_id: string } = JSON.parse(model!.content);
      const dimensionModle = await queryDimensions(app_id);
      steDimensions(dimensionModle.data);
      setLoading(false);
    }
  }, [fileInfoId]);

  useEffect(() => {
    fetchFn();
  }, [fetchFn]);

  const questions = useMemo(() => {
    if (fileInfo?.content) {
      const { questions }: { questions: IQuestionItem[] } = JSON.parse(fileInfo.content);
      // 预览的数据包含被软删除的数据
      const newQuestions = questions.map((c) => {
        if (c.type === EQuestionItemType.Star || c.type === EQuestionItemType.Option) {
          const options = c.options.filter((c) => c.is_deleted !== true);
          return { ...c, options };
        }
        if (c.type === EQuestionItemType.Matrix) {
          const options = c.options.filter((c) => c.is_deleted !== true);
          return { ...c, options };
        }
        return c;
      });
      handlePersonalRecommendQuestion(newQuestions, dimensions);
      console.log('newQuestions', newQuestions);
      const arr = initializeQuestions(newQuestions);
      console.log('newQuestions1', arr);
      console.log('convert2SurveyQuestions(arr):', convert2SurveyQuestions(arr));
      // 显示的问卷问题需要单独根据各种问题类型做预处理操作
      return convert2SurveyQuestions(arr);
    }
    return [];
  }, [fileInfo?.content, dimensions]);

  const uuid = useMemo(() => {
    const params = route.params;
    const taskinfo_id = params.taskinfo_id;
    // 如果有传递task信息的id过来，则使用uuid拼接
    if (taskinfo_id && q36) {
      // 补充id为6位
      const left = _.padStart(taskinfo_id, 6, '0');
      // 拼接uuid
      // 文档参考https://iwiki.woa.com/pages/viewpage.action?pageId=1689393596
      return `${left}-${q36}`;
    }
    return params.uuid || '';
  }, [route.params, q36]);

  useEffect(() => {
    if (uuid) {
      setTimeout(() => {
        MessagePlugin.success(`当前uuid为: ${uuid}`);
      }, 3000);
    }
  }, [uuid]);

  useEffect(() => {
    window.browser?.app?.getBrowserParam(
      (params) => {
        const { qimei36 } = params;
        setQ36(qimei36);
        MessagePlugin.success(`当前q36为: ${qimei36}`);
      },
      (error) => {
        MessagePlugin.error(JSON.stringify(error));
      },
    );
  }, []);

  if (loading) {
    return (
      <div style={{ height: 'calc(100vh)', width: 'calc(100vw)' }}>
        <Row className="w-100-p h-100-p" justify="center" align="middle" gutter={8}>
          <Col>
            <CusLoading />
          </Col>
          <Col className="c-pri-6 fs-16px fw-5">加载问卷内容中...</Col>
        </Row>
      </div>
    );
  }

  return (
    <div
      style={{
        height: 'calc(100vh)',
        width: 'calc(100vw)',
      }}
    >
      <ErrorBoundary>
        <QuestionPreviewer questions={questions} />
      </ErrorBoundary>
    </div>
  );
}

export default SurveyPreview;
